import { useEffect, useMemo, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import {
  useGetLetterListQuery,
  useLazyDeleteReceivedLetterQuery,
  useLazyPatchLetterReadQuery,
  useLazyPatchReceiveLikeQuery,
} from '~/api/LetterAPI';
import Letter from '~/classes/Letter';
import Pagination from '~/classes/Pagination';
import { DividerBlock, DividerLineBlock } from '~/components/Dividers';
import { FormWrapper } from '~/components/Form';
import { FavoriteBorderIcon, FavoriteIcon, LetterIcon, SendIcon } from '~/components/Icons';
import { ConfirmButton } from '~/components/Modal/Buttons';
import { LetterContainer } from '~/components/Modal/Containers';
import MenuPopup from '~/components/Modal/MenuPopup';
import TitleModal from '~/components/Modal/TitleModal';
import {
  CenterContainer,
  FlexContainer,
  SpaceBetweenContainer,
} from '~/components/Styled/Containers';
import { P14 } from '~/components/Styled/Texts';
import COLORS from '~/constants/Colors';
import { contentParser, toCustomLocale } from '~/helper/util';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { setLastReadLetter } from '~/reducers/letter';
import { shutter } from '~/reducers/modal';

import ReceivedLetterDeleteModal from './ReceivedLetterDeleteModal';

const P = styled(P14)`
  line-height: 24px;
  margin-left: 10px;
`;

const Week = styled(P14)`
  display: flex;
  line-height: 24px;
  color: ${COLORS.Manatee};
  justify-content: center;
`;

interface Props {
  open: boolean;
  close: () => void;
  letterRecipientId: number;
}

const ReceivedLetterModal = ({ open, close, letterRecipientId }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const alert = useAlert();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.me);
  const writeLetterReply = useSelector((state) => state.modal.writeLetterReply);

  const { data = new Pagination<Letter>(), refetch } = useGetLetterListQuery(letterRecipientId);

  const lastLetter = useMemo(() => data.data[data.data.length - 1], [data]);
  const [patchLetterRead] = useLazyPatchLetterReadQuery();

  const [patchReceiveLike] = useLazyPatchReceiveLikeQuery();
  const [isLikes, setIsLikes] = useState<boolean[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteReceivedLetter] = useLazyDeleteReceivedLetterQuery();

  const handleDeleteLetter = async () => {
    await deleteReceivedLetter({ letterRecipientId });

    setDeleteModalOpen(false);

    setTimeout(() => {
      alert.success('받은 편지함에서 삭제가 완료되었습니다');
      close();
    }, 100);
  };

  const isSentLetter = (letter: Letter) => {
    return letter.letterRecipient!.recipientUserId !== user.id;
  };

  useEffect(() => {
    if (!writeLetterReply) refetch();
  }, [refetch, writeLetterReply]);

  useEffect(() => {
    if (lastLetter?.letterRecipient && !lastLetter.letterRecipient.isRead) {
      patchLetterRead({
        letterId: lastLetter.letterRecipient.letterId,
        isRead: true,
      });
    }
  }, [lastLetter, patchLetterRead]);

  useEffect(() => {
    if (data.data.length > 0) {
      setIsLikes(
        data.data.map((letter, index) => (index % 2 === 0 ? true : letter.letterRecipient!.isLike)),
      );

      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView({});
        }
      }, 100);
    }
  }, [data]);

  return (
    <TitleModal title="받은 편지" open={open} close={close} zIndex={2000}>
      {data.data.map((letter, index) => (
        <FormWrapper key={letter.id} style={{ margin: '20px 0' }}>
          <SpaceBetweenContainer>
            <FlexContainer>
              {isSentLetter(letter) ? (
                <SendIcon />
              ) : isLikes[index] ? (
                <FavoriteIcon
                  onClick={() => {
                    const _isLikes = [...isLikes];
                    _isLikes[index] = false;
                    setIsLikes(_isLikes);
                    patchReceiveLike({ letterId: letter.id, isLike: false });
                  }}
                />
              ) : (
                <FavoriteBorderIcon
                  onClick={() => {
                    const _isLikes = [...isLikes];
                    _isLikes[index] = true;
                    setIsLikes(_isLikes);
                    patchReceiveLike({ letterId: letter.id, isLike: true });
                  }}
                />
              )}

              {isSentLetter(letter) ? <P>{user.nickname}</P> : <P>{letter.senderUser?.nickname}</P>}
            </FlexContainer>
            <Week>
              {toCustomLocale(letter.createdAt)}

              {isSentLetter(letter) ? (
                <div />
              ) : (
                <MenuPopup
                  items={[
                    { key: 1, label: <div onClick={() => setDeleteModalOpen(true)}>삭제하기</div> },
                  ]}
                />
              )}
            </Week>
          </SpaceBetweenContainer>

          <SpaceBetweenContainer
            style={{
              marginLeft: '24px',
              marginRight: isSentLetter(letter) ? undefined : '28px',
            }}
          >
            {isSentLetter(letter) ? <div /> : <P>받는 사람: {user.nickname}</P>}

            <Week>{letter.letterBox?.weeks}주차</Week>
          </SpaceBetweenContainer>

          <DividerLineBlock height={40} />

          <LetterContainer height={473}>
            {contentParser(letter?.screenedContent || '')}
          </LetterContainer>
        </FormWrapper>
      ))}

      <DividerBlock height={20} />

      <CenterContainer>
        <ConfirmButton
          disabled={
            lastLetter &&
            (lastLetter.letterRecipient?.isSentReply || lastLetter.letterRecipient?.letterId === 0)
          }
          icon={<LetterIcon />}
          text={'답장하기'}
          onClick={() => {
            dispatch(setLastReadLetter(lastLetter));
            dispatch(shutter({ writeLetterReply: true }));
          }}
        />
      </CenterContainer>

      <DividerBlock height={20} />

      <ReceivedLetterDeleteModal
        open={deleteModalOpen}
        close={() => setDeleteModalOpen(false)}
        confirm={handleDeleteLetter}
      />
      <div ref={ref} />
    </TitleModal>
  );
};

export default ReceivedLetterModal;
