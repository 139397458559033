import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import COLORS from '~/constants/Colors';

const StyledConfirmButton = styled.button`
  cursor: pointer;
  color: ${COLORS.White};
  font-size: 20px;
  line-height: 24px;

  background-color: ${COLORS.PoliceBlue};
  border: 0;
  border-radius: 10px;
  width: 100%;
  height: 48px;

  margin: 24px 0;

  &:disabled {
    cursor: not-allowed;
    background-color: ${COLORS.PoliceBlue50};
  }
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const ConfirmButton = ({ children, ...props }: ButtonProps) => {
  return <StyledConfirmButton {...props}>{children}</StyledConfirmButton>;
};

const StyledTextButton = styled.button`
  cursor: pointer;
  color: ${COLORS.PoliceBlue};
  font-size: 16px;
  line-height: 19px;
  background-color: ${COLORS.White};
  border: 0;
  padding: 4px;
`;

export const TextButton = ({ children, ...props }: ButtonProps) => {
  return (
    <StyledTextButton type="button" {...props}>
      {children}
    </StyledTextButton>
  );
};
