import styled from 'styled-components';

import COLORS from '~/constants/Colors';

const LogoWrapper = styled.p`
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.32em;
  color: ${COLORS.PoliceBlue80};
  margin: 0;
  height: 40px;
  font-family: Lumina;
`;

export const Logo = () => <LogoWrapper>UNKNOWN</LogoWrapper>;
