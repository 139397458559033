export default {
  common: {
    // tokenNeedRefresh: 'common.tokenNeedRefresh',
    // invalidToken: 'common.invalidToken',
    // invalidDate: 'common.invalidDate',
    notFoundId: 'common.notFoundId',
    // notAllowedRegex: 'common.notAllowedRegex',
    // notExistsKey: 'common.notExistsKey',
  },
  user: {
    alreadyExists: 'user.alreadyExists',
    alreadyNickname: 'user.alreadyNickname',
    alreadyPhone: 'user.alreadyPhone',
    alreadyUsedCode: 'user.alreadyUsedCode',
    notFoundEmail: 'user.notFoundEmail',
    notFoundCode: 'user.notFoundCode',
    // notVerified: 'user.notVerified',
    unauthorized: 'user.unauthorized',
    forbidden: 'user.forbidden',
    // alreadyRequestRole: 'user.alreadyRequestRole',
    // alreadyHasRole: 'user.alreadyHasRole',
    // hasNotRole: 'user.hasNotRole',
  },
  letterBox: {
    alreadyExistsServiceType: 'letterBox.alreadyExistsServiceType',
  },
  letter: {
    alreadySent: 'letter.alreadySent',
    notMyReceived: 'letter.notMyReceived',
    alreadyReplySent: 'letter.alreadyReplySent',
  },
};
