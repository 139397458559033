import { useState } from 'react';

import { ConfirmButton } from './Modal/Buttons';
import ConfirmModal from './Modal/ConfirmModal';
import { ButtonContainer, ContentContainer } from './Modal/Containers';
import { P16 } from './Styled/Texts';

const AlertTemplate = ({ message, options, close, ...props }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    close();
  };

  return (
    <ConfirmModal open={open} close={handleClose} zIndex={10000}>
      <ContentContainer>
        <P16 style={{ whiteSpace: 'pre' }}>{message}</P16>
      </ContentContainer>
      <ButtonContainer>
        <ConfirmButton text={'확인'} onClick={handleClose} />
      </ButtonContainer>
    </ConfirmModal>
  );
};

export default AlertTemplate;
