import LetterBox from './LetterBox';
import LetterRecipient from './LetterRecipient';
import ReplyLetter from './ReplyLetter';
import User from './User';

export enum LetterStatus {
  SUBMITTED = 'SUBMITTED', // 사용자 제출 완료
  SCREENING_OPERATOR_COMPLETE = 'SCREENING_OPERATOR_COMPLETE', // 1차 검수 완료
  SCREENING_OPERATOR_FAILURE = 'SCREENING_OPERATOR_FAILURE', // 1차 검수 실패
  SCREENING_COMPLETE = 'SCREENING_COMPLETE', // 2차 검수 완료
  SCREENING_FAILURE = 'SCREENING_FAILURE', // 2차 검수 실패
  SENT_COMPLETE = 'SENT_COMPLETE', // 각 주차가 끝나, 편지가 발송된 상태
}

export enum AmountOfRecipient {
  LESS = 'LESS',
  MORE = 'MORE',
}

export enum LetterDeleteStatus {
  SENDER_DELETE = 'SENDER_DELETE',
  OPERATOR_DELETE = 'OPERATOR_DELETE',
  ADMIN_DELETE = 'ADMIN_DELETE',
}

export default class Letter {
  id: number;
  senderUserId: number;
  senderUser?: User;
  originContent: string;
  screenedContent?: string;
  screenerUserId: number;
  screenerUser?: User;
  letterStatus: LetterStatus;
  amountOfRecipient: AmountOfRecipient;
  letterBoxId: number;
  letterBox?: LetterBox;
  replyLetterBoxId: number;
  replyLetterBox?: LetterBox;
  likes: number;
  agreeOfBestLetter: boolean;
  candidateOfBestLetter: boolean;
  letterDeleteStatus?: LetterDeleteStatus;
  letterFilteredReason?: string; // enum 으로 "비방", "욕설", 등을 넣어두어야 할 수도 있다.
  isReply: boolean;
  replyLetters?: ReplyLetter[];
  letterRecipient?: LetterRecipient;
  letterRecipients?: LetterRecipient[];
  createdAt: Date;
  updatedAt: Date;

  constructor(letter: Letter) {
    this.id = letter.id;
    this.senderUserId = letter.senderUserId;
    this.senderUser = letter.senderUser && new User(letter.senderUser);
    this.originContent = letter.originContent;
    this.screenedContent = letter.screenedContent;
    this.screenerUserId = letter.screenerUserId;
    this.screenerUser = letter.screenerUser && new User(letter.screenerUser);
    this.letterStatus = letter.letterStatus;
    this.amountOfRecipient = letter.amountOfRecipient;
    this.letterBoxId = letter.letterBoxId;
    this.letterBox = letter.letterBox && new LetterBox(letter.letterBox);
    this.replyLetterBoxId = letter.replyLetterBoxId;
    this.replyLetterBox = letter.replyLetterBox && new LetterBox(letter.replyLetterBox);
    this.likes = letter.likes;
    this.agreeOfBestLetter = letter.agreeOfBestLetter;
    this.candidateOfBestLetter = letter.candidateOfBestLetter;
    this.letterDeleteStatus = letter.letterDeleteStatus;
    this.letterFilteredReason = letter.letterFilteredReason;
    this.isReply = letter.isReply;
    this.replyLetters =
      letter.replyLetters && letter.replyLetters.map((replyLetter) => new ReplyLetter(replyLetter));
    this.letterRecipient = letter.letterRecipient && new LetterRecipient(letter.letterRecipient);
    this.letterRecipients =
      letter.letterRecipients &&
      letter.letterRecipients.map((letterRecipient) => new LetterRecipient(letterRecipient));
    this.createdAt = new Date(letter.createdAt);
    this.updatedAt = new Date(letter.updatedAt);
  }
}
