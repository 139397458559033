import { Gender } from '../User';

export class SignUpDto {
  email: string;
  password: string;
  realname: string;
  phone: string;
  birthday: string;
  gender: Gender;
  nickname: string;
  address: string;
  addressDetail: string;
  zipCode: string;
  agreeMarketing: boolean;

  constructor(signUpDto: SignUpDto) {
    this.email = signUpDto.email;
    this.password = signUpDto.password;
    this.realname = signUpDto.realname;
    this.phone = signUpDto.phone;
    this.birthday = signUpDto.birthday;
    this.gender = signUpDto.gender;
    this.nickname = signUpDto.nickname;
    this.address = signUpDto.address;
    this.addressDetail = signUpDto.addressDetail;
    this.zipCode = signUpDto.zipCode;
    this.agreeMarketing = signUpDto.agreeMarketing;
  }
}
