import { Col, Row, Space } from 'antd';
import { addMonths, format } from 'date-fns';
import { useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowForwardIcon, CreditCardIcon, LocalShippingIcon } from '~/components/Icons';
import Footer from '~/components/Layout/Footer';
import {
  CenterContainer,
  FlexContainer,
  SpaceBetweenContainer,
} from '~/components/Styled/Containers';
import { media } from '~/components/Styled/Media';
import COLORS from '~/constants/Colors';
import Path from '~/constants/Path';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { shutter } from '~/reducers/modal';

const Wrapper = styled.div`
  padding-top: 40px;
  width: 100%;

  ${media.tablet`
  `}

  ${media.mobile`
  `}
`;

const MainTitleWrapper = styled.div`
  margin-left: 60px;

  ${media.mobile`
    margin-left: 24px;
  `}
`;

const MainTitle = styled.div`
  display: flex;
  font-size: 64px;
  line-height: 76px;
  color: ${COLORS.PoliceBlue};
  margin-bottom: 40px;

  ${media.mobile`
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  `}
`;

const MainSubTitle = styled.h3`
  display: flex;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: ${COLORS.PoliceBlue};
  margin-bottom: 80px;

  ${media.tablet`
    margin-bottom: 120px;
  `}

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 40px;
  `}
`;

const ReviewBoxsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 92px;

  ${media.tablet`
    margin-bottom: 92px;
  `}

  ${media.mobile`
    margin-bottom: 12px;
  `}
`;

const ReviewBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 12px;
  width: 328px;

  ${media.mobile`
    margin-top: 4px;
  `}
`;

const ReviewBox = styled.div`
  align-items: flex-start;
  box-shadow: 0px 0px 8px rgba(63, 76, 95, 0.1);
  border-radius: 16px;
  padding: 16px;
  height: 120px;
  margin-bottom: 16px;
`;

const Reviewer = styled.div`
  font-size: 14px;
  line-height: 17px;

  ${media.tablet`
    margin-bottom: 16px;
  `}

  ${media.mobile`
    margin-bottom: 0px;
  `}
`;

const Reviews = [
  {
    review:
      '나의 진솔한 이야기를 터놓을 수 있어서 좋았고, 매주 기다리는 게 있는 일상이 행복했고, 모든 사람의 삶이 아름다고 소중하다는 것을 느낄 수 있었습니다.',
    reviewer: '텀블벅 프로젝트 후원자 A',
  },
  {
    review: '매주 편지를 기다리면서 우체통을 들락날락했습니다.',
    reviewer: '텀블벅 프로젝트 후원자 B',
  },
  {
    review:
      '누군가에게 편지를 받아보는 게 참 오랜만이었습니다. 나중에 하나씩 읽어봐도 새로운 즐거움이 될 것 같아요.',
    reviewer: '텀블벅 프로젝트 후원자 C',
  },
  {
    review:
      '아무 정보도 알지 못하는 타인과 편지를 나누는 것은 생각보다 더 즐겁고 편안한 일이네요. 오히려 서로를 모르기 때문에 편히 이야기를 나눌 수 있는 것 같아요!',
    reviewer: '텀블벅 프로젝트 후원자 D',
  },
  {
    review:
      '바쁘고 지치는 일상 속에서 설렘과 따뜻함. 그리고 연결되어있다는 느낌을 느낄 수 있었습니다.',
    reviewer: '텀블벅 프로젝트 후원자 E',
  },
];

const TicketButtonWrapper = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background: ${COLORS.Saffron50};
  border-radius: 16px;
  border-width: 0;
  padding: 40px;
  margin: 0 20px;
  margin-bottom: 150px;

  ${media.tablet`
    margin-bottom: 40px;
  `}
  ${media.mobile`
    width: 100%;
    padding: 16px;
    margin-bottom: 40px;
    & > div {
      justify-content: space-between;
      width: 100%;
    }
  `}


  & svg {
    margin-left: 40px;

    ${media.tablet`
      margin-left: 16px;
    `}

    ${media.mobile`
      width: 24px;
      height: 24px;
      margin-left: 8px;
    `}
  }
`;

const TicketButtonTitle = styled.div`
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;

  ${media.tablet`
    font-size: 32px;
    line-height: 40px;
  `}

  ${media.mobile`
    width: 230px;
    font-size: 24px;
    line-height: 29px;

    &.small {
      width: unset;
      font-size: 16px;
      line-height: 19px;
    }
  `}
`;

const TicketButtonSubTitle = styled.div`
  font-size: 36px;
  line-height: 43px;
  font-weight: 400;

  ${media.tablet`
    font-size: 28px;
    line-height: 32px;
  `}

  ${media.mobile`
    font-size: 14px;
    line-height: 17px;
  `}
`;

const Line = styled.div`
  border-top: 4px solid rgba(63, 76, 95, 0.1);

  ${media.mobile`
    border-top: 0px;
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 120px;

  ${media.mobile`
    padding-top: 40px;
  `}
`;

const OnlineTitle = styled.div`
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;
  white-space: pre;
  text-align: center;
  margin-bottom: 120px;

  ${media.tablet`
    margin-bottom: 120px;
  `}

  ${media.mobile`
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 40px;
  `}
`;

const OnlineSubTitle = styled.div`
  font-size: 36px;
  line-height: 43px;
  font-weight: 600;
  color: ${COLORS.Saffron};
  margin-bottom: 40px;

  ${media.mobile`
    font-size: 20px;
    line-height: 24px;
  `}
`;

const Image = styled.img`
  max-width: 720px;
  margin-bottom: 16px;

  ${media.mobile`
    padding: 0 16px;
    width: 100%;
  `}
`;

const ImageDesc = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  white-space: pre;
  text-align: center;

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;

    &.small {
      font-size: 14px;
      line-height: 17px;
    }
  `}
`;

const ServiceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${COLORS.BrightGray};
  padding: 48px 0;
  margin-bottom: 160px;

  ${media.mobile`
    margin-bottom: 40px;
  `}
`;

const ServiceBox = styled.div`
  background-color: ${COLORS.White};
  padding: 32px 40px;
  border-radius: 40px;
  width: 880px;
  margin-bottom: 40px;

  ${media.tablet`
    width: 720px;
  `}

  ${media.mobile`
    width: 360px;
    margin-bottom: 16px;
  `}
`;

const SpaceBetweenBox = styled(SpaceBetweenContainer)`
  width: 880px;

  ${media.tablet`
    width: 720px;
  `}

  ${media.mobile`
    width: 360px;
  `}
`;

const ServiceDescWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background: rgba(63, 76, 95, 0.05);
  border-radius: 40px;
  margin-bottom: 24px;

  ${media.mobile`
    padding: 12px 24px;
  `}
`;

const ServiceIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.mobile`
    & svg {
      width: 24px;
      height: 24px;
    }
  `}
`;

const ServiceIconDesc = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.Manatee};

  ${media.mobile`
    font-size: 14px;
    line-height: 17px;
  `}
`;

const StartButton = styled.button`
  cursor: pointer;
  width: 420px;
  height: 80px;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: ${COLORS.White};
  background: ${COLORS.Saffron};
  border: 0;
  border-radius: 16px;

  ${media.tablet`
    width: 352px;
  `}

  ${media.mobile`
    height: 48px;
    width: 172px;
    font-size: 16px;
    line-height: 19px;
  `}
`;

const Question = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 24px;

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;
  `}
`;

const Answer = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 80px;
  max-width: 880px;
  padding: 0 80px;

  ${media.mobile`
    font-size: 14px;
    line-height: 17px;
    padding: 0 24px;
    margin-bottom: 40px;
  `}
`;

const Ticket = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const today = useMemo(() => new Date(), []);

  const isSignIn = useSelector((state) => state.auth.isSignIn);

  const handleTicket = () => {
    if (!isSignIn) {
      alert.success('잠시만요, 회원가입 및 로그인 부탁드려요!', {
        onClose: () => {
          dispatch(shutter({ signIn: true }));
        },
      });
    } else {
      window.scrollTo({ top: 0 });
      navigate(Path.SUBSCRIPTION);
    }
  };

  return (
    <Wrapper>
      <MainTitleWrapper>
        <MainTitle style={{ margin: 0 }}>평범한 일상의 새로운 설렘,</MainTitle>
        <MainTitle>
          익명 우편
          <MainTitle style={{ color: `${COLORS.Saffron}`, margin: 0 }}>&nbsp;레터 언노운</MainTitle>
        </MainTitle>

        <MainSubTitle>누군지 몰라서 더 기대될 거에요.</MainSubTitle>
      </MainTitleWrapper>

      <ReviewBoxsWrapper>
        {Reviews.map((review) => (
          <ReviewBoxWrapper key={review.review}>
            <ReviewBox>{review.review}</ReviewBox>
            <Reviewer>{review.reviewer}</Reviewer>
          </ReviewBoxWrapper>
        ))}
      </ReviewBoxsWrapper>

      <CenterContainer>
        <TicketButtonWrapper onClick={handleTicket}>
          <Row justify={'space-between'}>
            <Col style={{ textAlign: 'start' }}>
              <TicketButtonTitle className="small" style={{ marginBottom: '8px' }}>
                오늘부터 익명 편지 시작하기 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </TicketButtonTitle>
              <TicketButtonSubTitle>매달 {today.getDate()}일 자동 결제 되어요</TicketButtonSubTitle>
            </Col>

            <Row align={'middle'}>
              <TicketButtonSubTitle style={{ justifyContent: 'center' }}>
                ￦10,000
              </TicketButtonSubTitle>
              <ArrowForwardIcon />
            </Row>
          </Row>
        </TicketButtonWrapper>
      </CenterContainer>

      <Line />

      <ContentWrapper>
        <OnlineTitle>온라인의 편리함은 더하고,{'\n'}종이 편지의 감동은 그대로</OnlineTitle>

        <OnlineSubTitle>매주 받아보는 종이편지</OnlineSubTitle>
        <Image src={'/img/sub_image1.jpg'} />
        <ImageDesc style={{ marginBottom: '80px' }}>매일 우편함을 들춰보게 될 거에요</ImageDesc>

        <OnlineSubTitle>편지 작성은 앱으로 편하게</OnlineSubTitle>
        <Image src={'/img/sub_image2.jpg'} />
        <ImageDesc style={{ marginBottom: '80px' }}>
          연필이 아닌, 앱과 웹으로{'\n'}편지를 보낼 수 있어요
        </ImageDesc>

        <OnlineSubTitle>언제든 꺼내 읽는 온라인 편지함</OnlineSubTitle>
        <Image src={'/img/sub_image3.jpg'} />
        <ImageDesc style={{ marginBottom: '40px' }}>
          받은 편지는 모두 저장되어{'\n'}언제든 확인할 수 있어요.
        </ImageDesc>
      </ContentWrapper>

      <ServiceWrapper>
        <ServiceBox>
          <TicketButtonTitle style={{ marginBottom: '16px' }}>
            언노운 익명 우편 서비스 (월 정기 구독)
          </TicketButtonTitle>
          <TicketButtonSubTitle style={{ marginBottom: '24px' }}>￦10,000/월</TicketButtonSubTitle>

          <ServiceDescWrapper>
            <ImageDesc className="small">매주 집으로 배송되는 실물 편지</ImageDesc>
            <ImageDesc className="small" style={{ color: COLORS.PoliceBlue50 }}>
              4회 이상
            </ImageDesc>
          </ServiceDescWrapper>

          <ServiceDescWrapper>
            <ImageDesc className="small">앱/웹에서 익명 편지 작성 가능</ImageDesc>
            <ImageDesc className="small" style={{ color: COLORS.PoliceBlue50 }}>
              1달
            </ImageDesc>
          </ServiceDescWrapper>

          <ServiceDescWrapper style={{ marginBottom: '40px' }}>
            <ImageDesc className="small">무제한 답장 가능</ImageDesc>
            <ImageDesc className="small" style={{ color: COLORS.PoliceBlue50 }}>
              1달
            </ImageDesc>
          </ServiceDescWrapper>

          <ServiceIconWrapper>
            <FlexContainer style={{ marginBottom: '8px' }}>
              <CreditCardIcon style={{ marginRight: '16px' }} />{' '}
              <ImageDesc className="small">다음 결제일</ImageDesc>
            </FlexContainer>
            <ServiceIconDesc style={{ marginBottom: '24px' }}>
              {format(addMonths(today, 1), 'yyyy년 M월 d일')}
            </ServiceIconDesc>
          </ServiceIconWrapper>

          <ServiceIconWrapper>
            <FlexContainer style={{ marginBottom: '8px' }}>
              <LocalShippingIcon style={{ marginRight: '16px' }} />
              <ImageDesc className="small">편지 발송일</ImageDesc>
            </FlexContainer>
            <ServiceIconDesc style={{ marginBottom: '40px' }}>
              구독 기간 내 매주 화요일 편지 발송 시작
            </ServiceIconDesc>
          </ServiceIconWrapper>

          <CenterContainer onClick={handleTicket}>
            <StartButton>지금 시작하기</StartButton>
          </CenterContainer>
        </ServiceBox>

        <SpaceBetweenBox>
          <StartButton onClick={handleTicket}>1달 구독하기</StartButton>
          <StartButton onClick={handleTicket}>2달 구독하기</StartButton>
        </SpaceBetweenBox>
      </ServiceWrapper>

      <CenterContainer style={{ flexDirection: 'column' }}>
        <OnlineTitle style={{ marginBottom: '40px' }}>자주 묻는 질문</OnlineTitle>

        <Question>편지는 어떻게 배송되나요?</Question>
        <Answer>
          매주 화요일 오후 중으로 우체국 일반 우편을 통해 배송이 시작됩니다. 3-5일이 평균 배송
          기간이나, 우체국 사정과 지역에 따라 도착일은 달라질 수 있습니다.
        </Answer>

        <Question>앱 활성화 기간이 지나면 편지를 볼 수 없나요?</Question>
        <Answer>
          정기 구독을 해지하신 뒤에도 앱과 웹을 통해 받은 편지와 보낸 편지를 확인하실 수 있습니다.
          단 회원 탈퇴시 모든 데이터는 삭제됩니다.
        </Answer>

        <Question>완전히 익명이 보장되나요?</Question>
        <Answer>
          서비스 이용 기간 동안 보내고 받게 되시는 모든 편지는 철저히 익명이 보장됩니다. 편지의
          수신인과 발신인은 절대 편지를 통해 자신을 특정할 수 있는 개인정보(성함, 거주지, 연락처)를
          노출할 수 없습니다.
        </Answer>
      </CenterContainer>

      <Footer color={COLORS.Manatee} />
    </Wrapper>
  );
};

export default Ticket;
