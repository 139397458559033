import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import COLORS from '~/constants/Colors';
import Path from '~/constants/Path';
import { useSelector } from '~/hooks/useRedux';
import Modals from '~/routes/Modals';

import { media } from '../Styled/Media';
import Header from './Header';
import LetterHeader from './LetterHeader';

const HomeBackgroundImage = styled.div`
  position: fixed;
  background: url('/img/home_background.svg');
  background-repeat: repeat;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${COLORS.PoliceBlue};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  max-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 110px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 32px;
  left: 20px;
  width: 253px;
  height: 46px;
  z-index: 10;

  max-width: calc(100% - 220px);
`;

const Logo = styled.img`
  cursor: pointer;
  content: url('/img/logo.svg');
  width: 100%;
  height: 100%;
`;

const LogoWhite = styled.div`
  cursor: pointer;
  content: url('/img/logo_white.svg');
  width: 100%;
  height: 100%;
`;

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      {location.pathname === Path.HOME && <HomeBackgroundImage />}
      <Wrapper>
        {location.pathname === Path.HOME && (
          <>
            <Header />

            <LogoWrapper>
              <LogoWhite />
            </LogoWrapper>
          </>
        )}
        {[Path.LETTER, Path.TICKET, Path.SUBSCRIPTION, Path.PAYMENT, Path.PAYMENT_SUCCESS].includes(
          location.pathname,
        ) && (
          <>
            <LetterHeader />

            <LogoWrapper>
              <Logo onPointerUp={() => navigate(Path.HOME)} />
            </LogoWrapper>
          </>
        )}

        <Outlet />
      </Wrapper>
      <Modals />
    </>
  );
};

export default Layout;
