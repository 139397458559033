import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useLazyPostFindPasswordQuery } from '~/api/UserAPI';
import { ConfirmButton, TextButton } from '~/components/Buttons';
import { DividerBlock } from '~/components/Dividers';
import { HelperLabel, Input } from '~/components/Form';
import { Logo } from '~/components/Logo';
import TitleModal from '~/components/Modal/TitleModal';
import { CenterContainer } from '~/components/Styled/Containers';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { shutter } from '~/reducers/modal';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 46px;
  margin-bottom: 16px;
`;

const FindPasswordModal = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal.findPassword);
  const [findPassword, { isError, isFetching }] = useLazyPostFindPasswordQuery();

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      realname: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required().email(),
      realname: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      if (!isFetching) {
        const { isSuccess } = await findPassword(values);

        if (isSuccess) {
          alert.success(`${values.realname}님의 이메일로 비밀번호을 보내드렸어요!`);
        }
      }
    },
  });

  return (
    <TitleModal
      title="비밀번호 찾기"
      open={open}
      close={() => dispatch(shutter({ findPassword: false }))}
      hideArrowIcon
    >
      <DividerBlock height={24} />

      <Logo />

      <DividerBlock height={48} />

      <form onSubmit={handleSubmit}>
        <Input
          label={'이메일'}
          value={values.email}
          onChange={handleChange}
          name="email"
          placeholder="이메일"
        />

        <Input
          label={'성함'}
          value={values.realname}
          onChange={handleChange}
          name="realname"
          placeholder="성함"
        />

        <ConfirmButton type="submit">확인</ConfirmButton>

        {isError && (
          <CenterContainer>
            <HelperLabel>성함과 휴대전화 정보가 일치하지 않습니다.</HelperLabel>
          </CenterContainer>
        )}

        <ButtonWrapper>
          <TextButton onClick={() => dispatch(shutter({ signUp: true }))}>회원가입</TextButton>
          <TextButton onClick={() => dispatch(shutter({ findPassword: false, signIn: true }))}>
            로그인
          </TextButton>
          <TextButton onClick={() => dispatch(shutter({ findPassword: false, findEmail: true }))}>
            아이디 찾기
          </TextButton>
        </ButtonWrapper>
      </form>
    </TitleModal>
  );
};

export default FindPasswordModal;
