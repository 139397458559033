import styled, { CSSObject } from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;
  position: absolute;
  flex-direction: column;
  text-align: center;
`;

const Image = styled.img`
  margin-bottom: 32px;
  max-width: 100%;
`;

interface Props {
  src: string;
  style: CSSObject;
  onClick: () => void;
}

const Door = ({ src, style, onClick }: Props) => {
  return (
    <Wrapper onClick={onClick} style={{ ...style }}>
      <Image src={src} onDragStart={(e) => e.preventDefault()} />
    </Wrapper>
  );
};

export default Door;
