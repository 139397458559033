import styled from 'styled-components';

import COLORS from '~/constants/Colors';

import { media } from '../Styled/Media';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
  flex-direction: column;

  padding: 40px;
`;

const Desc = styled.span`
  color: ${(props) => props.color};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;

  ${media.mobile`
    font-size: 12px;
    line-height: 16px;
  `}
  & > a {
    color: ${(props) => props.color};
  }
`;

interface Props {
  color: string;
}

const Footer = ({ color }: Props) => {
  return (
    <Wrapper>
      <Desc color={color}>언노운</Desc>
      <Desc color={color}>
        <a
          href="https://letterunknown-legalsharing.s3.ap-northeast-2.amazonaws.com/%E1%84%8B%E1%85%B5%E1%86%A8%E1%84%86%E1%85%A7%E1%86%BC+%E1%84%8B%E1%85%AE%E1%84%91%E1%85%A7%E1%86%AB+UNKNOWN+%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%80%E1%85%AA%E1%86%AB_v2.2pg.pdf"
          target="_blank"
        >
          이용약관
        </a>
        &nbsp;&nbsp;
        <a
          href="https://letterunknown-legalsharing.s3.ap-northeast-2.amazonaws.com/(1%E1%84%8E%E1%85%A1)+%E1%84%8B%E1%85%B5%E1%86%A8%E1%84%86%E1%85%A7%E1%86%BC+%E1%84%8B%E1%85%AE%E1%84%91%E1%85%A7%E1%86%AB+UNKNOWN+%E1%84%80%E1%85%A2%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9%E1%84%8E%E1%85%A5%E1%84%85%E1%85%B5%E1%84%87%E1%85%A1%E1%86%BC%E1%84%8E%E1%85%B5%E1%86%B7_%E1%84%80%E1%85%A2%E1%84%8C%E1%85%A5%E1%86%BC.pdf"
          target="_blank"
        >
          개인정보처리방침
        </a>
      </Desc>
      <Desc color={color}>대표자 : 이다연&nbsp;&nbsp; 사업자등록번호 : 434-08-02395</Desc>
      <Desc color={color}>통신판매 신고번호 : 02903929303</Desc>
      <Desc color={color}>
        주소 : 서울특별시 도봉구 시루봉로 107&nbsp;&nbsp; 대표 전화 : 02-6061-0016
      </Desc>
      <Desc color={color}>Email : hello@letterunknown.com</Desc>
    </Wrapper>
  );
};

export default Footer;
