import { Space } from 'antd';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import COLORS from '~/constants/Colors';

import { CheckIcon } from '../Icons';

const StyledConfirmButton = styled.button`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 24px;

  min-width: 92px;
  height: 48px;
  border: 0;
  border-radius: 10px;
  color: ${COLORS.White};
  background-color: ${COLORS.PoliceBlue};
  padding: 0 12px;

  & svg {
    margin-right: 4px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${COLORS.PoliceBlue50};
  }
`;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  icon?: ReactNode;
  onClick?: () => void;
}

export const ConfirmButton = ({ text, onClick, icon = <CheckIcon />, ...props }: Props) => {
  return (
    <StyledConfirmButton {...props} onClick={onClick}>
      {icon}
      {text}
    </StyledConfirmButton>
  );
};

const StyledCancelButton = styled.button`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 24px;

  min-width: 92px;
  height: 48px;
  border: 0;
  border-radius: 10px;
  color: ${COLORS.White};
  background-color: ${COLORS.PoliceBlue50};
  padding: 0 12px;

  & svg {
    margin-right: 4px;
  }
`;

export const CancelButton = ({ text, onClick, ...props }: Props) => {
  return (
    <StyledCancelButton {...props} onClick={onClick}>
      <CheckIcon />
      {text}
    </StyledCancelButton>
  );
};
