import { ConfirmButton } from '~/components/Modal/Buttons';
import ConfirmModal from '~/components/Modal/ConfirmModal';
import { ButtonContainer, ContentContainer } from '~/components/Modal/Containers';
import { P16 } from '~/components/Styled/Texts';

interface Props {
  open: boolean;
  close: () => void;
}

const TicketModal = ({ open, close }: Props) => {
  return (
    <ConfirmModal open={open} close={close}>
      <ContentContainer>
        <P16>
          {
            '2022.01.15 까지 월드비전 후원님들께선\n이용권 구매없이 서비스를 사용하실 수 있습니다.\n2023년 상반기때 돌아올게요!'
          }
        </P16>
      </ContentContainer>
      <ButtonContainer>
        <ConfirmButton text={'확인'} onClick={close} />
      </ButtonContainer>
    </ConfirmModal>
  );
};
export default TicketModal;
