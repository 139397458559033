import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { usePostSubscriptionCallbackMutation } from '~/api/UserAPI';
import { PaymentStatus } from '~/classes/Dto/CreatePaymentDto';
import { CenterContainer } from '~/components/Styled/Containers';
import { media } from '~/components/Styled/Media';
import COLORS from '~/constants/Colors';
import Path from '~/constants/Path';

const Wrapper = styled(CenterContainer)`
  width: 100%;
  flex-direction: column;

  ${media.mobile`
    margin-bottom: 120px;
  `}
`;

const Title = styled.div`
  font-size: 36px;
  line-height: 43px;

  ${media.mobile`
    font-size: 24px;
    line-height: 29px;
  `}
`;

const HomeButton = styled.button`
  cursor: pointer;
  width: 480px;
  height: 80px;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: ${COLORS.White};
  background: ${COLORS.Saffron};
  border-radius: 16px;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 0;

  ${media.mobile`
    width: 360px;
    height: 48px;
    font-size: 16px;
    line-height: 19px;
  `}
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;
  `}
`;

const StoreWrapper = styled.div``;

const Image = styled.img`
  cursor: pointer;
  width: 80px;
  height: 80px;
  margin: 40px;
`;

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get('order_id');
  const orderCode = searchParams.get('order_code');
  const paymentStatus = searchParams.get('status')?.toUpperCase();

  console.log(orderId, orderCode, paymentStatus);

  const [postSubscriptionCallback] = usePostSubscriptionCallbackMutation();

  const handleHomeButton = () => {
    navigate(Path.HOME);
  };

  const handleAppstore = () => {
    console.log('appstore');
  };

  const handlePlaystore = () => {
    console.log('playstore');
  };

  useEffect(() => {
    if (orderId && orderCode && paymentStatus === PaymentStatus.SUCCESS) {
      postSubscriptionCallback({ orderId: +orderId, orderCode, paymentStatus });
    }
  }, [orderCode, orderId, postSubscriptionCallback, paymentStatus]);

  return (
    <Wrapper>
      <Title>결제가 완료되었어요.</Title>
      <Title>UNKNOWN에 오신걸 환영합니다!</Title>

      <HomeButton onClick={handleHomeButton}>홈 화면으로</HomeButton>

      <Desc>앱으로도 이용하실 수 있어요</Desc>

      <StoreWrapper>
        <Image onClick={handleAppstore} src={'/img/appstore.png'} />
        <Image onClick={handlePlaystore} src={'/img/playstore.png'} />
      </StoreWrapper>
    </Wrapper>
  );
};

export default PaymentSuccess;
