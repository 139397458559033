import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useGetBoardQuery } from '~/api/BoardAPI';
import { useLazyGetSendableQuery } from '~/api/LetterAPI';
import { Board, BoardType } from '~/classes/Board';
import Pagination from '~/classes/Pagination';
import { media } from '~/components/Styled/Media';
import { P36 } from '~/components/Styled/Texts';
import COLORS from '~/constants/Colors';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { getWeeks } from '~/reducers/letter';
import { shutter } from '~/reducers/modal';

const Logo = styled(P36)`
  display: flex;
  text-align: center;
  align-items: center;
  user-select: text;
  position: absolute;
  top: -13rem;
  color: ${COLORS.PoliceBlue};
  font-family: Pretendard-ExtraLight;
  display: flex;
  white-space: pre;
  height: 144px;

  ${media.tablet`
    top: -10rem;
    font-size: 30px;
    line-height: 40px;
    height: 120px;
  `}

  ${media.mobile`
    top: -8rem;
    font-size: 20px;
    line-height: 28px;
    height: 84px;
  `}
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;

  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);

  user-select: none;

  ${media.tablet`
    font-size: 36px;
    line-height: 40px;
  `}
`;

const LetterTableImage = styled.img`
  pointer-events: none;
  z-index: 1;
  height: auto;
  width: 500px;

  ${media.mobile`
    width: 350px;
  `}
`;

const BackgroundLine = styled.div`
  position: fixed;
  top: 80%;
  border-bottom: 2px solid ${COLORS.PoliceBlue80};
  width: 200vw;
`;

const TopicImage = styled.img`
  cursor: pointer;
  position: fixed;
  z-index: 2;
  width: 100px;
  left: 124px;
  top: 105px;

  position: fixed;
  content: url('/img/letter_table_topic.gif');

  &:hover {
    content: url('/img/letter_table_topic_hover.gif');
  }

  ${media.mobile`
    width: 70px;
    left: 87px;
    top: 74px;
  `}
`;

const LetterBoxImage = styled.img`
  cursor: pointer;
  position: fixed;
  z-index: 2;
  width: 193px;
  left: 15px;
  top: 221px;

  position: fixed;
  content: url('/img/letter_table_box.gif');

  &:hover {
    content: url('/img/letter_table_box_hover.gif');
  }

  ${media.mobile`
    width: 134px;
    left: 12px;
    top: 156px;
  `}
`;

const LetterCalendarImage = styled.img`
  cursor: pointer;
  position: fixed;
  z-index: 2;
  width: 120px;
  left: 245px;
  top: 196px;

  position: fixed;
  content: url('/img/letter_table_calendar.gif');

  &:hover {
    content: url('/img/letter_table_calendar_hover.gif');
  }

  ${media.mobile`
    width: 80px;
    left: 170px;
    top: 140px;
  `}
`;

const LetterPaperImage = styled.img`
  cursor: pointer;
  position: fixed;
  z-index: 2;
  width: 145px;
  left: 179px;
  top: 310px;

  position: fixed;
  content: url('/img/letter_table_paper.gif');

  &:hover {
    content: url('/img/letter_table_paper_hover.gif');
  }

  ${media.mobile`
    width: 100px;
    left: 126px;
    top: 217px;
  `}
`;

const Letter = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFirstWeek = useSelector((state) => state.auth.me.isFirstWeek);
  const modal = useSelector((state) => state.modal);

  const noticeBoard = useSelector((state) => state.modal.noticeBoard);
  const [getSendable, { data }] = useLazyGetSendableQuery();

  const { data: boardList = new Pagination<Board>() } = useGetBoardQuery({
    page: 1,
    limit: 10,
    boardType: BoardType.NOTICE,
  });

  const isNewNotice = useMemo(() => {
    const readArray: number[] = JSON.parse(window.localStorage.getItem('noticeRead') || '[]');

    return boardList.data.some((board) => {
      const pivot = moment(board.createdAt);
      pivot.add(2, 'day');

      return !readArray.includes(board.id) && pivot > moment(new Date());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardList, noticeBoard]);

  const logoSentence = useMemo(() => {
    // TODO: 오픈 전까지는 일단 막자.
    return '반갑습니다.\n이곳은 UNKNOWN 입니다.';

    // if (!data) {
    //   return '';
    // }

    // if (isNewNotice) {
    //   return 'UNKNOWN의\n새 공지사항이 도착했습니다.\n지금 확인해보세요.';
    // }

    // if (isFirstWeek) {
    //   if (data.weekly) {
    //     return '반갑습니다.\n이곳은 UNKNOWN 입니다.';
    //   } else {
    //     return '첫 편지 작성을 축하드립니다!\n다음주 월요일 6시\n첫 편지를 받아보실 수 있어요.';
    //   }
    // }

    // if (!data.hasSent) {
    //   return '아직 편지를 써본 적이 없으시군요.\n금주의 주제를 참고해보시는건 어떠세요?';
    // }

    // if (data.weekly && data.reply) {
    //   return '새로운 주차가 시작되었습니다.\n누군가에게 내 이야기를 전해보세요.';
    // } else if (!data.weekly && data.reply) {
    //   return '아직 답장을 작성하지 않으셨어요.\n당신을 기다리고 있을 분에게\n기쁨을 전해보세요.';
    // } else if (data.weekly && !data.reply) {
    //   return '오늘은 어떤 일이 있었나요?\n누군가와 나누고픈 이야기가\n떠올랐다면 새 편지를 작성해보세요.';
    // } else if (!data.weekly && !data.reply) {
    //   return '모두의 편지를 확인해보세요.\n이번 주엔 어떤 편지가\n선정되었을까요?';
    // }
  }, [isNewNotice, isFirstWeek, data]);

  const handlePaper = async () => {
    const { data } = await getSendable();

    if (data?.weekly) {
      dispatch(shutter({ writeLetter: true }));
    } else {
      alert.error('이번주엔 이미 편지를 보냈습니다.');
    }
  };

  useEffect(() => {
    dispatch(getWeeks());
  }, [dispatch]);

  useEffect(() => {
    const imagesPreload = [
      '/img/letter_table_topic.gif',
      '/img/letter_table_topic_hover.gif',
      '/img/letter_table_box.gif',
      '/img/letter_table_box_hover.gif',
      '/img/letter_table_calendar.gif',
      '/img/letter_table_calendar_hover.gif',
      '/img/letter_table_paper.gif',
      '/img/letter_table_paper_hover.gif',
    ];
    imagesPreload.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    });
  }, []);

  useEffect(() => {
    getSendable();
  }, [modal, getSendable]);

  return (
    <Wrapper>
      <BackgroundLine />
      <LetterTableImage src={'/img/letter_table.png'} onDragStart={(e) => e.preventDefault()} />
      <TopicImage onPointerUp={() => dispatch(shutter({ weeklyTopicBoard: true }))} />
      <LetterBoxImage onPointerUp={() => dispatch(shutter({ letterList: true }))} />
      <LetterCalendarImage onPointerUp={() => dispatch(shutter({ noticeBoard: true }))} />
      <LetterPaperImage onPointerUp={handlePaper} />
      <Logo>{logoSentence}</Logo>
    </Wrapper>
  );
};

export default Letter;
