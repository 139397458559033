import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import * as Yup from 'yup';

import { ConfirmButton, TextButton } from '~/components/Buttons';
import { DividerBlock } from '~/components/Dividers';
import { Input } from '~/components/Form';
import { Logo } from '~/components/Logo';
import TitleModal from '~/components/Modal/TitleModal';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { signIn } from '~/reducers/auth';
import { shutter } from '~/reducers/modal';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 46px;
  margin-bottom: 16px;
`;

const SignInModal = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const open = useSelector((state) => state.modal.signIn);

  const { values, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required().email(),
      password: Yup.string().required().min(8).max(32),
    }),
    onSubmit: async (values) => {
      try {
        await dispatch(signIn(values)).unwrap();
        dispatch(shutter({ signIn: false }));

        resetForm();
      } catch {
        alert.error('아이디 혹은 비밀번호가 틀렸습니다.');
      }
    },
  });

  return (
    <TitleModal
      title="회원가입 / 로그인"
      open={open}
      close={() => dispatch(shutter({ signIn: false }))}
      hideArrowIcon
    >
      <DividerBlock height={24} />

      <Logo />

      <DividerBlock height={48} />

      <form onSubmit={handleSubmit}>
        <Input
          label={'아이디'}
          value={values.email}
          onChange={handleChange}
          name="email"
          placeholder="이메일"
        />

        <Input
          label={'비밀번호'}
          value={values.password}
          onChange={handleChange}
          name="password"
          placeholder="비밀번호"
          inputType="password"
        />

        <ConfirmButton type="submit">로그인</ConfirmButton>

        <ButtonWrapper>
          <TextButton onClick={() => dispatch(shutter({ signUp: true }))}>회원가입</TextButton>

          <TextButton onClick={() => dispatch(shutter({ signIn: false, findEmail: true }))}>
            아이디 찾기
          </TextButton>

          <TextButton onClick={() => dispatch(shutter({ signIn: false, findPassword: true }))}>
            비밀번호 찾기
          </TextButton>
        </ButtonWrapper>
      </form>
    </TitleModal>
  );
};

export default SignInModal;
