import { Space } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useDaumPostcodePopup, Address } from 'react-daum-postcode';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useLazyPatchMyQuery } from '~/api/UserAPI';
import { MyProfileDto } from '~/classes/Dto/MyProfileDto';
import { ConfirmButton } from '~/components/Buttons';
import { FormWrapper, Label } from '~/components/Form';
import { EditIcon } from '~/components/Icons';
import {
  ColumnContainer,
  FlexContainer,
  SpaceBetweenContainer,
} from '~/components/Styled/Containers';
import COLORS from '~/constants/Colors';
import { phoneToDisplay } from '~/helper/util';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { reload } from '~/reducers/auth';

const Wrapper = styled.div`
  width: 100%;

  & label {
    margin: 0 8px 0 8px;
    font-size: 16px;
    line-height: 19px;
  }
`;

const InputWrapper = styled.div`
  color: ${COLORS.PoliceBlue};
  min-height: 36px;

  font-size: 16px;
  line-height: 36px;

  display: flex;
  align-items: center;
  justify-content: start;

  & > div + div {
    margin-left: 16px;
    margin-top: 0;
  }

  & + div {
    margin-top: 4px;
  }
`;

const Input = styled.input`
  justify-content: center;
  border: 1px solid ${COLORS.PoliceBlue};
  border-radius: 5px;
  padding: 4px 8px;

  width: 100%;
  height: 32px;

  ::placeholder {
    color: ${COLORS.Manatee};
  }
`;

const DisplayLabel = styled.label``;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const MiniButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 12px;

  & > button {
    height: 24px;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 12px;
    margin: 0;
  }
`;

const SaveButton = styled.button`
  cursor: pointer;
  text-decoration-line: underline;
  border: 0;
  background-color: unset;
  font-size: 16px;
  line-height: 19px;
`;

const LetterForm = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector((state) => state.auth.me);
  const [isModifyMode, setIsModifyMode] = useState(false);
  const postcodePopup = useDaumPostcodePopup();
  const [patchMy] = useLazyPatchMyQuery();

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      realname: user.realname,
      phone1: user.phone.slice(0, 3),
      phone2: user.phone.slice(3, 7),
      phone3: user.phone.slice(7),
      address: user.address,
      addressDetail: user.addressDetail,
      zipCode: user.zipCode,
    },
    validationSchema: Yup.object({
      realname: Yup.string().required(),
      phone1: Yup.string().required(),
      phone2: Yup.string().required().length(4),
      phone3: Yup.string().required().length(4),
      zipCode: Yup.string().required(),
      address: Yup.string().required(),
      addressDetail: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      const myProfile = new MyProfileDto({
        realname: values.realname,
        phone: `${values.phone1}${values.phone2}${values.phone3}`,
        zipCode: values.zipCode,
        address: values.address,
        addressDetail: values.addressDetail,
      });

      const { isSuccess } = await patchMy(myProfile);
      await dispatch(reload()).unwrap();

      if (isSuccess) {
        alert.success('변경하신 정보가 성공적으로 반영되었습니다.');
        setIsModifyMode(false);
      }
    },
  });

  const handleComplete = (data: Address) => {
    setFieldValue('zipCode', data.zonecode);
    setFieldValue('address', data.address);
  };

  const handlePostPopup = () => {
    postcodePopup({ onComplete: handleComplete });
  };

  return (
    <Wrapper>
      <SpaceBetweenContainer>
        <Label>편지 배송지 수정</Label>
        <IconWrapper>
          {isModifyMode ? (
            <SaveButton type="submit" onClick={() => handleSubmit()}>
              저장
            </SaveButton>
          ) : (
            <EditIcon onClick={() => setIsModifyMode(!isModifyMode)} />
          )}
        </IconWrapper>
      </SpaceBetweenContainer>

      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <InputWrapper>
            <Label>받는분</Label>

            {isModifyMode ? (
              <Input
                value={values.realname}
                onChange={handleChange}
                name="realname"
                placeholder="3-7자 이내의 한글"
              />
            ) : (
              <DisplayLabel>{user.realname}</DisplayLabel>
            )}
          </InputWrapper>

          <InputWrapper>
            <Label>휴대전화</Label>

            {isModifyMode ? (
              <Space>
                <Input
                  value={values.phone1}
                  onChange={handleChange}
                  name="phone1"
                  placeholder="000"
                  style={{ width: '3em' }}
                />
                <Input
                  value={values.phone2}
                  onChange={handleChange}
                  name="phone2"
                  placeholder="0000"
                  style={{ width: '4em' }}
                />
                <Input
                  value={values.phone3}
                  onChange={handleChange}
                  name="phone3"
                  placeholder="0000"
                  style={{ width: '4em' }}
                />
              </Space>
            ) : (
              <DisplayLabel>{phoneToDisplay(user.phone)}</DisplayLabel>
            )}
          </InputWrapper>

          <InputWrapper>
            <Label style={{ marginTop: '8px', marginBottom: 'auto' }}>배송지</Label>
            {isModifyMode ? (
              <ColumnContainer style={{ width: '100%' }}>
                <FlexContainer
                  onClick={handlePostPopup}
                  style={{ marginBottom: '6px', width: '100%' }}
                >
                  <MiniButtonWrapper>
                    <ConfirmButton type="button" onClick={handlePostPopup}>
                      주소검색
                    </ConfirmButton>
                  </MiniButtonWrapper>

                  <Input
                    onClick={handlePostPopup}
                    value={values.zipCode}
                    onChange={handleChange}
                    name="zipCode"
                    placeholder="우편번호"
                    type="number"
                    disabled
                    style={{ width: '6em' }}
                  />
                </FlexContainer>

                <div onClick={handlePostPopup} style={{ marginBottom: '6px' }}>
                  <Input
                    value={values.address}
                    onChange={handleChange}
                    name="address"
                    placeholder="주소"
                    disabled
                    style={{ cursor: 'pointer', width: '100%' }}
                  />
                </div>

                <InputWrapper>
                  <Input
                    value={values.addressDetail}
                    onChange={handleChange}
                    name="addressDetail"
                    placeholder="상세주소"
                  />
                </InputWrapper>
              </ColumnContainer>
            ) : (
              <ColumnContainer style={{ marginTop: '8px' }}>
                <DisplayLabel style={{ lineHeight: '24px' }}>{user.zipCode}</DisplayLabel>
                <DisplayLabel style={{ lineHeight: '24px' }}>{user.address}</DisplayLabel>
                <DisplayLabel style={{ lineHeight: '24px' }}>{user.addressDetail}</DisplayLabel>
              </ColumnContainer>
            )}
          </InputWrapper>
        </form>
      </FormWrapper>
    </Wrapper>
  );
};

export default LetterForm;
