import styled from 'styled-components';

export const P36 = styled.p`
  font-weight: 200;
  font-size: 36px;
  line-height: 48px;
`;

export const P20 = styled.p`
  font-size: 20px;
  line-height: 24px;
`;

export const P16 = styled.p`
  font-size: 16px;
  line-height: 19px;
`;

export const P14 = styled.p`
  font-size: 14px;
  line-height: 17px;
`;
