import Letter from './Letter';

export default class ReplyLetter {
  id: number;
  receivedLetterId: number;
  received?: Letter;
  replyLetterId: number;
  reply?: Letter;

  constructor(replyLetter: ReplyLetter) {
    this.id = replyLetter.id;
    this.receivedLetterId = replyLetter.receivedLetterId;
    this.received = replyLetter.received && new Letter(replyLetter.received);
    this.replyLetterId = replyLetter.replyLetterId;
    this.reply = replyLetter.reply && new Letter(replyLetter.reply);
  }
}
