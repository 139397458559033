import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre;
  text-align: center;
  min-height: 154px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > button + button {
    margin-left: 80px;
  }
`;

export const LetterContainer = styled.div<{ height: number }>`
  font-size: 16px;
  line-height: 19px;
  overflow-y: auto;
  height: ${(props) => props.height}px;
`;
