import { addMonths, format, startOfDay } from 'date-fns';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Address, useDaumPostcodePopup } from 'react-daum-postcode';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useLazyPatchMyQuery, usePostSubscriptionMutation } from '~/api/UserAPI';
import { PaymentType } from '~/classes/Dto/CreatePaymentDto';
import { CheckYellowIcon } from '~/components/Icons';
import Footer from '~/components/Layout/Footer';
import {
  CenterContainer,
  ColumnContainer,
  FlexContainer,
  RowContainer,
  SpaceBetweenContainer,
} from '~/components/Styled/Containers';
import { media } from '~/components/Styled/Media';
import COLORS from '~/constants/Colors';
import Path from '~/constants/Path';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { reload } from '~/reducers/auth';

const Wrapper = styled(CenterContainer)`
  width: 100%;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 48px;
  line-height: 57px;
  padding: 40px;
  min-width: 100%;
  border-bottom: 1px solid ${COLORS.PoliceBlue10};

  ${media.mobile`
    font-size: 24px;
    line-height: 29px;
  `}
`;

const ContentWrapper = styled.div`
  width: 880px;

  ${media.tablet`
    width: 100%;
    padding: 0 32px;
  `}

  ${media.mobile`
    width: 100%;
    padding: 0 20px;
  `}
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  ${media.tablet`
    width: 100%;
  `}

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;
    margin-top: 40px;
    margin-bottom: 16px;
    justify-content: flex-start;
  `}
`;

const DescTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;
  `}
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.Black50};

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;
  `}
`;

const Line = styled.div`
  margin-bottom: 16px;
  border-bottom: 0.5px solid ${COLORS.Manatee};
`;

const ShippingTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  margin: 16px 0;

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;
  `}
`;

const BackgroundInput = styled.input`
  display: flex;
  width: 100%;
  padding: 16px;
  height: 80px;
  background: ${COLORS.PoliceBlue05};
  border-radius: 16px;
  border: 0;

  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 16px;

  ${media.mobile`
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 17px;
  `}
`;

const SearchButton = styled.button`
  cursor: pointer;

  width: 120px;
  height: 80px;

  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.White};
  background: #febe33;
  border-radius: 16px;
  border: 0;
  margin-bottom: 16px;
  margin-left: 40px;

  ${media.mobile`
    width: 66px;
    height: 40px;
    padding: 0 12px;
    font-size: 16px;
    line-height: 19px;
    margin-left: 24px;
  `}
`;

const RadioLabel = styled.label`
  cursor: pointer;
  display: flex;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin: 10px 0;

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;
    margin: 6px 0;
  `}
`;
const Radio = styled.input`
  margin-right: 16px;
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 0;
  background-color: ${COLORS.LightSilver};
  border-radius: 50%;

  :checked {
    background-color: ${COLORS.Manatee};
    border: 8px solid ${COLORS.LightSilver};
  }

  ${media.mobile`
    width: 16px;
    height: 16px;

    :checked {
      background-color: ${COLORS.Manatee};
      border: 4px solid ${COLORS.LightSilver};
    }
  `}
`;

const PaymentButton = styled.button`
  cursor: pointer;
  width: 480px;
  height: 80px;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: ${COLORS.White};
  background: ${COLORS.Saffron};
  border-radius: 16px;
  border: 0;

  :disabled {
    opacity: 0.5;
  }

  ${media.mobile`
    height: 48px;
    font-size: 16px;
    line-height: 19px;
  `}
`;

const Payment = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const paymentType = (searchParams.get('paymentType') as PaymentType) || PaymentType.MONTHLY;

  const [startDate, endDate] = useMemo(() => {
    const today = startOfDay(new Date());
    return [today, addMonths(today, paymentType === PaymentType.TWO_MONTH ? 2 : 1)];
  }, [paymentType]);

  const me = useSelector((state) => state.auth.me);
  const [agree, setAgree] = useState(false);
  const [patchMy] = useLazyPatchMyQuery();
  const [postSubscription, { data }] = usePostSubscriptionMutation();

  const handleAgree = useCallback(() => {
    setAgree(!agree);
  }, [agree]);

  const open = useDaumPostcodePopup();

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      recipient: me.recipient,
      phone: me.phone,
      zipCode: me.zipCode,
      address: me.address,
      addressDetail: me.addressDetail,
    },
    validationSchema: Yup.object({
      recipient: Yup.string().required(),
      phone: Yup.string().required().length(11),
      zipCode: Yup.string().required(),
      address: Yup.string().required(),
      addressDetail: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      console.log('onSubmit', values);

      await patchMy({
        recipient: values.recipient,
        phone: values.phone,
        address: values.address,
        addressDetail: values.addressDetail,
        zipCode: values.zipCode,
      }).unwrap();
      await dispatch(reload()).unwrap();

      await postSubscription({ paymentType }).unwrap();
    },
  });

  const handleComplete = (data: Address) => {
    setFieldValue('zipCode', data.zonecode);
    setFieldValue('address', data.address);
  };

  const handlePost = () => {
    open({ onComplete: handleComplete });
  };

  useEffect(() => {
    if (!data) return;

    const { orderCode } = data;

    const successUrl = `${process.env.REACT_APP_HOST}${Path.PAYMENT_SUCCESS}`;
    const errorUrl = `${process.env.REACT_APP_HOST}${Path.PAYMENT}`;
    const cancelUrl = `${process.env.REACT_APP_HOST}${Path.PAYMENT}`;

    function popupPay() {
      const url = `https://api.steppay.kr/api/public/orders/${orderCode}/pay?successUrl=${successUrl}&errorUrl=${errorUrl}&cancelUrl=${cancelUrl}`;
      const name = 'popup test';
      const option = 'width = 425, height = 812, top = 100, left = 200, location = no';
      window.open(url, name, option);
    }

    popupPay();
  }, [data]);

  return (
    <Wrapper>
      <Title>결제 정보 입력</Title>

      <ContentWrapper>
        <SubTitle>{paymentType === PaymentType.MONTHLY ? '구독 정보' : '상품 정보'}</SubTitle>

        <ColumnContainer style={{ padding: '0 16px' }}>
          <RowContainer style={{ marginBottom: '8px' }}>
            <DescTitle style={{ marginRight: '16px' }}>
              {paymentType === PaymentType.MONTHLY ? '구독 상품' : '구매 상품'}
            </DescTitle>
            <Desc>
              {paymentType === PaymentType.MONTHLY && '익명 우편 언노운 월 정기이용권'}
              {paymentType === PaymentType.ONE_MONTH && '익명 우편 언노운 1달 이용권'}
              {paymentType === PaymentType.TWO_MONTH && '익명 우편 언노운 2달 이용권'}
            </Desc>
          </RowContainer>
          <RowContainer style={{ marginBottom: '8px' }}>
            <DescTitle style={{ marginRight: '16px' }}>
              {paymentType === PaymentType.MONTHLY ? '구독 기간' : '상품 기간'}
            </DescTitle>
            <Desc>
              {format(startDate, 'yyyy.MM.dd')} ~ {format(endDate, 'yyyy.MM.dd')}
            </Desc>
          </RowContainer>
          {paymentType === PaymentType.MONTHLY && (
            <RowContainer style={{ marginBottom: '8px' }}>
              <DescTitle style={{ marginRight: '16px' }}>다음 결제일</DescTitle>
              <Desc>{format(endDate, 'yyyy.MM.dd')}</Desc>
            </RowContainer>
          )}
        </ColumnContainer>

        <SubTitle>이용권 내용</SubTitle>

        <ColumnContainer style={{ padding: '0 16px' }}>
          <RowContainer style={{ marginBottom: '8px' }}>
            <DescTitle style={{ marginRight: '16px', textAlign: 'start' }}>
              1. 매주 집으로 발송되는 익명 종이편지
            </DescTitle>
            <Desc style={{ textAlign: 'start' }}>
              배송일 및 편지 발송 기간은 아래 배송 정책 참조
            </Desc>
          </RowContainer>

          <RowContainer style={{ marginBottom: '8px' }}>
            <DescTitle style={{ marginRight: '16px', textAlign: 'start' }}>
              2. 레터 언노운 앱, 웹 {paymentType === PaymentType.TWO_MONTH ? 2 : 1}달 이용권
            </DescTitle>
            <Desc style={{ textAlign: 'start' }}>편지/답장 발송 및 편지 아카이빙 기능 제공</Desc>
          </RowContainer>
        </ColumnContainer>

        <SubTitle>결제금액</SubTitle>

        <ColumnContainer style={{ padding: '0 16px' }}>
          <SpaceBetweenContainer style={{ marginBottom: '8px' }}>
            <DescTitle style={{ marginRight: '16px' }}>상품 가격</DescTitle>
            <Desc>{paymentType === PaymentType.TWO_MONTH ? '₩20,000' : '₩10,000'}</Desc>
          </SpaceBetweenContainer>

          {paymentType === PaymentType.MONTHLY && (
            <SpaceBetweenContainer style={{ marginBottom: '8px' }}>
              <DescTitle style={{ marginRight: '16px' }}>매달 결제 금액</DescTitle>
              <Desc>₩10,000</Desc>
            </SpaceBetweenContainer>
          )}

          <Line />

          <SpaceBetweenContainer style={{ marginBottom: '8px' }}>
            <DescTitle style={{ marginRight: '16px', fontWeight: 600, color: COLORS.Black }}>
              결제금액
            </DescTitle>
            <Desc style={{ fontWeight: 600, color: COLORS.Black }}>
              {paymentType === PaymentType.TWO_MONTH ? '₩20,000' : '₩10,000'}
            </Desc>
          </SpaceBetweenContainer>
        </ColumnContainer>

        <ColumnContainer>
          <SubTitle style={{ justifyContent: 'flex-start' }}>배송지 정보</SubTitle>

          <ShippingTitle style={{ marginTop: 0 }}>받는 사람</ShippingTitle>
          <BackgroundInput
            placeholder="이름"
            name="recipient"
            value={values.recipient}
            onChange={handleChange}
            style={{ width: '50%' }}
          />

          <ShippingTitle>주소</ShippingTitle>
          <SpaceBetweenContainer style={{ width: '100%' }}>
            <BackgroundInput
              name="address"
              value={values.address}
              onChange={handleChange}
              placeholder="주소"
              style={{ cursor: 'pointer' }}
              readOnly
              onClick={handlePost}
            />
            <SearchButton onClick={handlePost}> 검색</SearchButton>
          </SpaceBetweenContainer>
          <BackgroundInput
            name="addressDetail"
            placeholder="상세주소"
            value={values.addressDetail}
            onChange={handleChange}
            style={{ cursor: 'pointer' }}
          />

          <ShippingTitle>휴대폰 번호</ShippingTitle>
          <BackgroundInput
            name="phone"
            placeholder="받는 분 휴대폰 번호"
            value={values.phone}
            onChange={handleChange}
            style={{ width: '50%' }}
          />
        </ColumnContainer>

        <ColumnContainer>
          <SubTitle style={{ fontWeight: 700 }}>약관 동의</SubTitle>

          <FlexContainer style={{ cursor: 'pointer', marginBottom: '24px' }} onClick={handleAgree}>
            <CheckYellowIcon color={agree ? COLORS.Saffron : COLORS.LightSilver} />
            <DescTitle style={{ color: COLORS.Black, marginLeft: '16px' }}>전체 동의하기</DescTitle>
          </FlexContainer>

          <SpaceBetweenContainer style={{ marginBottom: '32px' }}>
            <FlexContainer>
              <Desc style={{ color: COLORS.Saffron, minWidth: '40px' }}>[필수]</Desc>
              <Desc style={{ color: COLORS.Black }}>&nbsp;개인정보 수집 및 이용 동의</Desc>
            </FlexContainer>
            <Desc style={{ color: COLORS.Black, textDecoration: 'underline', minWidth: '40px' }}>
              <a
                href="https://letterunknown-legalsharing.s3.ap-northeast-2.amazonaws.com/%E1%84%85%E1%85%A6%E1%84%90%E1%85%A5+%E1%84%8B%E1%85%A5%E1%86%AB%E1%84%82%E1%85%A9%E1%84%8B%E1%85%AE%E1%86%AB+%E1%84%80%E1%85%A2%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9+%E1%84%89%E1%85%AE%E1%84%8C%E1%85%B5%E1%86%B8+%E1%84%86%E1%85%B5%E1%86%BE+%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC+%E1%84%83%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%B4_%E1%84%87%E1%85%A2%E1%84%89%E1%85%A9%E1%86%BC.pdf"
                target="_blank"
                style={{ color: COLORS.Black }}
              >
                보기
              </a>
            </Desc>
          </SpaceBetweenContainer>
          <SpaceBetweenContainer style={{ marginBottom: '32px' }}>
            <FlexContainer>
              <Desc style={{ color: COLORS.Saffron, minWidth: '40px' }}>[필수]</Desc>
              <Desc style={{ color: COLORS.Black }}>
                &nbsp;가격, 내용 등 상품 정보에 대한 정기구독 안내 확인 동의
              </Desc>
            </FlexContainer>
            <Desc style={{ color: COLORS.Black, textDecoration: 'underline', minWidth: '40px' }}>
              <a
                href="https://letterunknown-legalsharing.s3.ap-northeast-2.amazonaws.com/%E1%84%80%E1%85%A1%E1%84%80%E1%85%A7%E1%86%A8%2C+%E1%84%82%E1%85%A2%E1%84%8B%E1%85%AD%E1%86%BC+%E1%84%83%E1%85%B3%E1%86%BC+%E1%84%89%E1%85%A1%E1%86%BC%E1%84%91%E1%85%AE%E1%86%B7%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9%E1%84%8B%E1%85%A6+%E1%84%83%E1%85%A2%E1%84%92%E1%85%A1%E1%86%AB+%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%80%E1%85%B5%E1%84%80%E1%85%AE%E1%84%83%E1%85%A9%E1%86%A8+%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%82%E1%85%A2+%E1%84%92%E1%85%AA%E1%86%A8%E1%84%8B%E1%85%B5%E1%86%AB+%E1%84%83%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%B4.pdf"
                target="_blank"
                style={{ color: COLORS.Black }}
              >
                보기
              </a>
            </Desc>
          </SpaceBetweenContainer>
          <SpaceBetweenContainer style={{ marginBottom: '32px' }}>
            <FlexContainer>
              <Desc style={{ color: COLORS.Saffron, minWidth: '40px' }}>[필수]</Desc>
              <Desc style={{ color: COLORS.Black }}>&nbsp;배송 정책 확인 동의</Desc>
            </FlexContainer>
            <Desc style={{ color: COLORS.Black, textDecoration: 'underline', minWidth: '40px' }}>
              <a
                href="https://letterunknown-legalsharing.s3.ap-northeast-2.amazonaws.com/UNKNOWN+%E1%84%87%E1%85%A2%E1%84%89%E1%85%A9%E1%86%BC+%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%8E%E1%85%A2%E1%86%A8.pdf"
                target="_blank"
                style={{ color: COLORS.Black }}
              >
                보기
              </a>
            </Desc>
          </SpaceBetweenContainer>
          <SpaceBetweenContainer style={{ marginBottom: '88px' }}>
            <FlexContainer>
              <Desc style={{ color: COLORS.Saffron, minWidth: '40px' }}>[필수]</Desc>
              <Desc style={{ color: COLORS.Black }}>&nbsp;환불 정책 확인 동의</Desc>
            </FlexContainer>
            <Desc style={{ color: COLORS.Black, textDecoration: 'underline', minWidth: '40px' }}>
              <a
                href="https://letterunknown-legalsharing.s3.ap-northeast-2.amazonaws.com/%E1%84%92%E1%85%AA%E1%86%AB%E1%84%87%E1%85%AE%E1%86%AF%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%8E%E1%85%A2%E1%86%A8+v1.1.pdf"
                target="_blank"
                style={{ color: COLORS.Black }}
              >
                보기
              </a>
            </Desc>
          </SpaceBetweenContainer>
        </ColumnContainer>

        <CenterContainer>
          <PaymentButton type="button" disabled={!agree} onClick={() => handleSubmit()}>
            결제하기
          </PaymentButton>
        </CenterContainer>
      </ContentWrapper>
      <Footer color={COLORS.Manatee} />
    </Wrapper>
  );
};

export default Payment;
