import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import LetterAPI from '~/api/LetterAPI';
import Letter, { AmountOfRecipient, LetterStatus } from '~/classes/Letter';
import LetterBox from '~/classes/LetterBox';
import { ServiceType } from '~/classes/User';

export const getWeeks = createAsyncThunk('letter/getWeeks', async (_, { rejectWithValue }) => {
  try {
    const letterBox = await LetterAPI.getWeeks();

    return letterBox;
  } catch (err) {
    console.log(err);
    return rejectWithValue(err.response?.data || err.message);
  }
});

export interface LetterState {
  lastReadLetter: Letter;
  letterBox: LetterBox;
}

const initialState: LetterState = {
  lastReadLetter: {
    id: 0,
    senderUserId: 0,
    originContent: '',
    screenerUserId: 0,
    letterStatus: LetterStatus.SUBMITTED,
    amountOfRecipient: AmountOfRecipient.LESS,
    letterBoxId: 0,
    replyLetterBoxId: 0,
    likes: 0,
    agreeOfBestLetter: false,
    candidateOfBestLetter: false,
    isReply: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  letterBox: {
    id: 0,
    weeks: 0,
    serviceType: ServiceType.OFFICIAL,
    isClosed: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
};

export const letter = createSlice({
  name: 'letter',
  initialState,
  reducers: {
    setLastReadLetter: (state, action: PayloadAction<Letter>) => {
      state.lastReadLetter = action.payload;
    },
  },
  extraReducers: {
    [getWeeks.pending.type]: (state, action) => {},
    [getWeeks.fulfilled.type]: (state, { payload: letterBox }: PayloadAction<LetterBox>) => {
      state.letterBox = letterBox;
    },
    [getWeeks.rejected.type]: (state, action: PayloadAction) => {},
  },
});

// Action creators are generated for each case reducer function
export const setLastReadLetter = letter.actions.setLastReadLetter;

export default letter.reducer;
