import { useMemo } from 'react';
import styled from 'styled-components';

import { ArrowLeftIcon, ArrowRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from '../Icons';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;

  & svg {
    cursor: pointer;

    &.disable {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const PageNumber = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  user-select: none;
  width: 30px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }

  &.current {
    text-decoration-line: underline;
  }
`;

export interface PaginationProps {
  page: number; // 현재 페이지
  limit: number; // 페이지별 표시할 갯수
  total: number; // 총 데이터 갯수
  onPageClick: (page: number) => void;
}

const Pagination = ({ page, limit, total, onPageClick }: PaginationProps) => {
  const maxPage = useMemo(() => Math.ceil(total / limit), [total, limit]);
  const pageSector = useMemo(() => {
    const prefix = Math.floor(page / 10) * 10; // 페이지 앞에 다시 붙일 단위
    const pages = page % 10 <= 5 ? [1, 2, 3, 4, 5] : [6, 7, 8, 9, 10];

    return pages.map((page) => page + prefix);
  }, [page]);

  return (
    <Wrapper>
      <DoubleArrowLeftIcon
        className={pageSector[0] !== 1 ? undefined : 'disable'}
        onClick={() => onPageClick(pageSector[0] - 5)}
      />
      <ArrowLeftIcon
        className={page !== 1 ? undefined : 'disable'}
        onClick={() => onPageClick(page - 1)}
      />
      {pageSector.map((p) => (
        <PageNumber
          key={p}
          className={`${p <= maxPage ? '' : 'disable'} ${p !== page ? '' : 'current'}`}
          onClick={() => onPageClick(p)}
        >
          {p}
        </PageNumber>
      ))}
      <ArrowRightIcon
        className={page < maxPage ? undefined : 'disable'}
        onClick={() => onPageClick(page + 1)}
      />
      <DoubleArrowRightIcon
        className={pageSector[4] < maxPage ? undefined : 'disable'}
        onClick={() => onPageClick(pageSector[0] + 5)}
      />
    </Wrapper>
  );
};

export default Pagination;
