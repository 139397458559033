import { Modal, ModalProps } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

import COLORS from '~/constants/Colors';

import { ArrowBackIcon, CloseYellowIcon } from '../Icons';

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    position: relative;
    min-height: 250px;
    border: 3px solid ${COLORS.PoliceBlue};
    border-radius: 10px;
    padding: 47px 0 0 0;
  }

  & .ant-modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    max-height: 770px;
    overflow-y: auto;
    font-size: 20px;
    line-height: 24px;
  }
`;

const TitleWrapper = styled.div`
  color: ${COLORS.White};
  position: absolute;
  top: -1px;
  left: -1px;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 2px);
  height: 48px;
  padding: 12px;
  background-color: ${COLORS.PoliceBlue};
  border-radius: 3px 3px 0 0;
`;

interface Props extends ModalProps {
  title: string;
  open: boolean;
  close: () => void;
  hideArrowIcon?: boolean;
  children: ReactNode;
}
const Space = styled.div`
  width: 24px;
`;

const TitleModal = ({ title, open, close, children, hideArrowIcon, ...props }: Props) => {
  return (
    <StyledModal
      open={open}
      onCancel={close}
      centered
      destroyOnClose
      footer={false}
      closable={false}
      width={592}
      {...props}
    >
      <TitleWrapper>
        {!hideArrowIcon ? <ArrowBackIcon onClick={close} /> : <Space />}
        {title}
        <CloseYellowIcon onClick={close} />
      </TitleWrapper>
      {children}
    </StyledModal>
  );
};
export default TitleModal;
