import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import TitleModal from '~/components/Modal/TitleModal';
import { CenterContainer } from '~/components/Styled/Containers';
import { P16 } from '~/components/Styled/Texts';
import Path from '~/constants/Path';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { shutter } from '~/reducers/modal';

const Image = styled.img`
  width: 100%;
`;

const GuideModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const open = useSelector((state) => state.modal.guide);

  useEffect(() => {
    const isViewGuide = localStorage.getItem('isViewGuide');
    if (location.pathname === Path.LETTER && isViewGuide === null) {
      dispatch(shutter({ guide: true }));
    }
  }, [dispatch, location]);

  return (
    <TitleModal
      title={'UNKNOWN 이용 안내'}
      open={open}
      close={() => {
        dispatch(shutter({ guide: false }));
        localStorage.setItem('isViewGuide', 'true');
      }}
      zIndex={5000}
      hideArrowIcon
    >
      <Image src={'/img/guide.png'} />

      <CenterContainer style={{ flexDirection: 'column' }}>
        <P16>노란색으로 반짝이는 물건들을 클릭해보세요.</P16>
        <P16>각 물건마다 숨겨진 기능을 확인하실 수 있답니다.</P16>
      </CenterContainer>
    </TitleModal>
  );
};
export default GuideModal;
