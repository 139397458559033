import styled from 'styled-components';

import { Board } from '~/classes/Board';
import { DividerLineBlock } from '~/components/Dividers';
import { FormWrapper } from '~/components/Form';
import { PushPinIcon } from '~/components/Icons';
import { LetterContainer } from '~/components/Modal/Containers';
import TitleModal from '~/components/Modal/TitleModal';
import { FlexContainer, SpaceBetweenContainer } from '~/components/Styled/Containers';
import { P14 } from '~/components/Styled/Texts';
import COLORS from '~/constants/Colors';
import { contentParser, toCustomLocale } from '~/helper/util';

const P = styled(P14)`
  line-height: 24px;
  margin-left: 10px;
`;

const Week = styled(P14)`
  line-height: 24px;
  color: ${COLORS.Manatee};
`;

interface Props {
  open: boolean;
  close: () => void;
  board: Board;
}

const NoticeModal = ({ open, close, board }: Props) => {
  return (
    <TitleModal title="공지사항" open={open} close={close} zIndex={2000}>
      <FormWrapper style={{ margin: '20px 0' }}>
        <SpaceBetweenContainer>
          <FlexContainer>
            <PushPinIcon />
            <P>{board.title}</P>
          </FlexContainer>
          <Week>{toCustomLocale(board.createdAt)}</Week>
        </SpaceBetweenContainer>

        <DividerLineBlock height={40} />

        <LetterContainer height={585}>{contentParser(board.content)}</LetterContainer>
      </FormWrapper>
    </TitleModal>
  );
};

export default NoticeModal;
