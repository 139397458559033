export enum PaymentStatus {
  SUCCESS = 'SUCCESS',
  REFUND = 'REFUND',
}

export enum PaymentType {
  MONTHLY = 'MONTHLY',
  ONE_MONTH = 'ONE_MONTH',
  TWO_MONTH = 'TWO_MONTH',
}

export default class CreatePaymentDto {
  orderId: number;
  orderCode: string;
  paymentStatus: PaymentStatus;

  constructor(createPaymentDto: CreatePaymentDto) {
    this.orderId = createPaymentDto.orderId;
    this.orderCode = createPaymentDto.orderCode;
    this.paymentStatus = createPaymentDto.paymentStatus;
  }
}
