import { Badge } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { useGetBoardQuery } from '~/api/BoardAPI';
import { Board, BoardType } from '~/classes/Board';
import Pagination from '~/classes/Pagination';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import { P16 } from '~/components/Styled/Texts';
import Table, { Column } from '~/components/Table';
import COLORS from '~/constants/Colors';
import { dateFormatting } from '~/helper/util';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import useReadState from '~/hooks/useReadArray';

import NoticeModal from './NoticeModal';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoticeList = () => {
  const [pagination, setPage] = usePaginationRequest();
  const [openDetail, setOpenDetail] = useState(false);
  const [board, setBoard] = useState<Board>();
  const [isReadArray, setIsReadArray, includes] = useReadState('noticeRead');

  const { data = new Pagination<Board>() } = useGetBoardQuery({
    ...pagination,
    boardType: BoardType.NOTICE,
  });

  const columns: Column<Board>[] = [
    {
      title: '제목',
      index: 'title',
      style: { width: '100%' },
      render: (title, board) => (
        <SpaceBetweenContainer>
          <P16>{title}</P16>
          {!includes(board.id, board.createdAt) && <Badge color={COLORS.Saffron} />}
        </SpaceBetweenContainer>
      ),
    },
    {
      title: '업로드 날짜',
      index: 'createdAt',
      style: { minWidth: '95px', display: 'flex', justifyContent: 'center' },
      render: (createAt: Date) => <>{dateFormatting(createAt)}</>,
    },
  ];

  return (
    <Wrapper>
      <Table
        dataSource={data.data}
        columns={columns}
        noContentDescription={'아직 공지사항이 없어요.'}
        onRowClick={(board) => {
          setBoard(board);
          setOpenDetail(true);
          setIsReadArray([...isReadArray, board.id]);
        }}
        pagination={{
          page: pagination.page,
          limit: pagination.limit,
          total: data?.count,
          onPageClick: (page) => setPage(page),
        }}
      />

      {board && <NoticeModal open={openDetail} close={() => setOpenDetail(false)} board={board} />}
    </Wrapper>
  );
};

export default NoticeList;
