import { DeleteOutlineIcon } from '~/components/Icons';
import { CancelButton, ConfirmButton } from '~/components/Modal/Buttons';
import ConfirmModal from '~/components/Modal/ConfirmModal';
import { ButtonContainer, ContentContainer } from '~/components/Modal/Containers';
import { P16 } from '~/components/Styled/Texts';

interface Props {
  open: boolean;
  close: () => void;
  confirm: () => void;
}

const ReceivedLetterDeleteModal = ({ open, close, confirm }: Props) => {
  return (
    <ConfirmModal open={open} close={close} zIndex={5000}>
      <ContentContainer>
        <P16>{'정말 편지를 삭제하시겠어요?\n삭제된 편지는 복구가 어렵답니다.'}</P16>
      </ContentContainer>
      <ButtonContainer>
        <CancelButton text={'취소'} onClick={close} />
        <ConfirmButton icon={<DeleteOutlineIcon />} text={'삭제'} onClick={confirm} />
      </ButtonContainer>
    </ConfirmModal>
  );
};
export default ReceivedLetterDeleteModal;
