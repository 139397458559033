import { Dropdown, type MenuProps } from 'antd';
import { ReactNode } from 'react';

import COLORS from '~/constants/Colors';

import { MoreVertIcon } from '../Icons';

interface Props {
  Button?: ReactNode;
  items: MenuProps['items'];
}

const MenuPopup = ({
  Button = (
    <MoreVertIcon cursor={'pointer'} fill={COLORS.PoliceBlue} style={{ marginLeft: '4px' }} />
  ),
  items,
}: Props) => {
  return (
    <Dropdown
      trigger={['click']}
      menu={{ items }}
      overlayStyle={{
        zIndex: 10000,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
        minWidth: '166px',
      }}
    >
      {Button}
    </Dropdown>
  );
};

export default MenuPopup;
