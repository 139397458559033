import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import * as Yup from 'yup';

import { CheckOutlined } from '@ant-design/icons';

import { useLazyPatchMyQuery, useLazyPostNicknameCheckQuery } from '~/api/UserAPI';
import { ConfirmButton } from '~/components/Buttons';
import { FormWrapper, HelperLabel, Label } from '~/components/Form';
import { EditIcon } from '~/components/Icons';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import COLORS from '~/constants/Colors';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { reload } from '~/reducers/auth';
import PasswordModal from '~/routes/Home/PasswordModal';

const Wrapper = styled.div`
  width: 100%;

  & label {
    margin: 0 8px 0 8px;
    font-size: 16px;
    line-height: 19px;
  }
`;

const InputWrapper = styled.div`
  color: ${COLORS.PoliceBlue};
  min-height: 32px;

  font-size: 16px;
  line-height: 32px;

  display: flex;
  align-items: center;
  justify-content: start;

  & > div + div {
    margin-left: 16px;
    margin-top: 0;
  }

  & + div {
    margin-top: 4px;
  }
`;

const Input = styled.input`
  justify-content: center;
  border: 1px solid ${COLORS.PoliceBlue};
  border-radius: 5px;
  padding: 4px 8px;

  width: 100%;
  height: 32px;
  margin-right: 4px;

  &:disabled {
    background: ${COLORS.BrightGray};
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.16);
    border: 0;
    border-radius: 5px;
  }

  ::placeholder {
    color: ${COLORS.Manatee};
  }
`;

const DisplayLabel = styled.label``;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const MiniButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 12px;

  & > button {
    height: 24px;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 12px;
    margin: 0;
  }
`;

const SaveButton = styled.button`
  cursor: pointer;
  text-decoration-line: underline;
  border: 0;
  background-color: unset;
  font-size: 16px;
  line-height: 19px;
`;

const ProfileForm = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector((state) => state.auth.me);
  const [isModifyMode, setIsModifyMode] = useState(false);
  const [postNicknameCheck] = useLazyPostNicknameCheckQuery();
  const [patchMy] = useLazyPatchMyQuery();
  const [passwordOpen, setPasswordOpen] = useState(false);

  const { values, errors, handleChange, handleSubmit, resetForm, setFieldValue } = useFormik({
    initialValues: {
      nickname: user.nickname,
    },
    validationSchema: Yup.object({
      nickname: Yup.string()
        .required()
        .min(3)
        .max(7)
        .matches(/^[가-힣\s]+$/),
    }),
    onSubmit: async (values) => {
      const trim = values.nickname.trim();
      if (trim === user.nickname || trim === '') {
        setIsModifyMode(false);
        return;
      }

      const { isSuccess, isError } = await patchMy(values);

      if (isSuccess) {
        await dispatch(reload()).unwrap();
        alert.success('변경하신 정보가 성공적으로 반영되었습니다.');
        setIsModifyMode(false);
      }

      if (isError) {
        alert.error('이미 사용중인 닉네임입니다.');
      }
    },
  });

  const checkDuplication = async () => {
    if (values.nickname.length === 0) {
      return;
    }

    const { data } = await postNicknameCheck({ nickname: values.nickname.trim() });

    if (data?.success) {
      alert.success('사용할 수 있는 닉네임입니다.');
    } else {
      alert.error('이미 사용중인 닉네임입니다.');
    }
  };

  useEffect(() => {
    setFieldValue('nickname', user.nickname);
  }, [setFieldValue, user.nickname]);

  return (
    <>
      <Wrapper>
        <SpaceBetweenContainer>
          <Label>내 정보 수정</Label>

          <IconWrapper>
            {isModifyMode ? (
              <SaveButton type="submit" onClick={() => handleSubmit()}>
                저장
              </SaveButton>
            ) : (
              <EditIcon onClick={() => setIsModifyMode(!isModifyMode)} />
            )}
          </IconWrapper>
        </SpaceBetweenContainer>

        <FormWrapper>
          <form onSubmit={handleSubmit}>
            <InputWrapper>
              <Label>닉네임</Label>

              {isModifyMode ? (
                <>
                  <Input
                    value={values.nickname}
                    onChange={handleChange}
                    name="nickname"
                    placeholder="3-7자 이내의 한글"
                  />

                  <MiniButtonWrapper>
                    <ConfirmButton type="button" onClick={checkDuplication}>
                      <CheckOutlined />
                    </ConfirmButton>
                  </MiniButtonWrapper>
                </>
              ) : (
                <DisplayLabel>{user.nickname}</DisplayLabel>
              )}
            </InputWrapper>

            {isModifyMode && values.nickname.length > 0 && errors.nickname && (
              <InputWrapper>
                <Label></Label>

                <HelperLabel>3-7자 이내의 한글을 입력해주세요.</HelperLabel>
              </InputWrapper>
            )}

            <InputWrapper>
              <Label>이메일</Label>
              <DisplayLabel>{user.email}</DisplayLabel>
            </InputWrapper>

            <InputWrapper>
              <Label>비밀번호</Label>
              {isModifyMode ? (
                <div onClick={() => setPasswordOpen(true)} style={{ cursor: 'pointer' }}>
                  <Input value={'********'} disabled />
                </div>
              ) : (
                <DisplayLabel>{'********'}</DisplayLabel>
              )}
            </InputWrapper>
          </form>
        </FormWrapper>
      </Wrapper>
      <PasswordModal open={passwordOpen} close={() => setPasswordOpen(false)} />
    </>
  );
};

export default ProfileForm;
