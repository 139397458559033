export default {
  // both
  HOME: '/',
  EMAIL_VERIFICATION: '/email-verification',

  // without authentication
  TICKET: '/ticket',

  // with authentication
  LETTER: '/letter',
  SUBSCRIPTION: '/subscription',
  PAYMENT: '/payment',
  PAYMENT_SUCCESS: '/payment/success',
};
