import { useState } from 'react';
import styled from 'styled-components';

import TitleModal from '~/components/Modal/TitleModal';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import '~/reducers/auth';
import { shutter } from '~/reducers/modal';

import LetterForm from './LetterForm';
import ProfileForm from './ProfileForm';

const Container = styled.div`
  width: 100%;

  & > div + div {
    margin-top: 40px;
  }
`;

const MyProfileModal = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal.myProfile);
  const [isModifyModeProfile, setIsModifyModeProfile] = useState(false);

  return (
    <TitleModal
      title="마이페이지"
      open={open}
      close={() => dispatch(shutter({ myProfile: false }))}
      hideArrowIcon
    >
      <Container>
        <ProfileForm />
        <LetterForm />
      </Container>
    </TitleModal>
  );
};

export default MyProfileModal;
