import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useLazyDeleteLetterQuery, useLazyPatchLetterQuery } from '~/api/LetterAPI';
import Letter, { LetterDeleteStatus, LetterStatus } from '~/classes/Letter';
import { DividerBlock, DividerLineBlock } from '~/components/Dividers';
import { FormWrapper, HelperLabel, TextArea } from '~/components/Form';
import { FavoriteIcon, LetterIcon, SendIcon } from '~/components/Icons';
import { CancelButton, ConfirmButton } from '~/components/Modal/Buttons';
import { ButtonContainer, LetterContainer } from '~/components/Modal/Containers';
import MenuPopup from '~/components/Modal/MenuPopup';
import TitleModal from '~/components/Modal/TitleModal';
import {
  CenterContainer,
  FlexContainer,
  SpaceBetweenContainer,
} from '~/components/Styled/Containers';
import { P14 } from '~/components/Styled/Texts';
import COLORS from '~/constants/Colors';
import { contentParser, toCustomLocale } from '~/helper/util';
import { useSelector } from '~/hooks/useRedux';

import SentLetterDeleteModal from './SentLetterDeleteModal';

const P = styled(P14)`
  line-height: 24px;
  margin-left: 10px;
`;

const Week = styled(P14)`
  display: flex;
  line-height: 24px;
  color: ${COLORS.Manatee};
  justify-content: center;
`;

interface Props {
  open: boolean;
  close: () => void;
  letter: Letter;
}

const SentLetterModal = ({ open, close, letter }: Props) => {
  const alert = useAlert();
  const user = useSelector((state) => state.auth.me);
  const [patchLetterReply] = useLazyPatchLetterQuery();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteLetter] = useLazyDeleteLetterQuery();

  const isModifiable = useMemo(
    () =>
      (!letter.isReply && !letter.letterBox?.isClosed) ||
      (letter.isReply && !letter.replyLetterBox?.isClosed),
    [letter.isReply, letter.letterBox?.isClosed, letter.replyLetterBox?.isClosed],
  );

  const isFilteredLetter = useMemo(
    () =>
      !isModifiable &&
      (letter.letterDeleteStatus === LetterDeleteStatus.OPERATOR_DELETE ||
        letter.letterDeleteStatus === LetterDeleteStatus.ADMIN_DELETE),
    [isModifiable, letter.letterDeleteStatus],
  );

  const [isModifyMode, setIsModifyMode] = useState(false);

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      letterId: letter.id,
      originContent: letter.originContent,
    },
    validationSchema: Yup.object({
      originContent: Yup.string().required().min(300).max(1200),
    }),
    onSubmit: async (values) => {
      console.log(values);

      const lines = (values.originContent.match(/\n/g) || []).length;
      if (lines > 32) {
        return alert.error('최대 줄바꿈 제한을 넘었습니다.');
      }

      const { isSuccess, isError } = await patchLetterReply(values);

      if (isSuccess) {
        alert.success('수정이 완료되었습니다.');
        close();
      }

      if (isError) {
        alert.error('수정에 실패했습니다.');
      }
    },
  });

  const handleDeleteLetter = async () => {
    await deleteLetter({ letterId: letter.id });

    setDeleteModalOpen(false);

    setTimeout(() => {
      alert.success('보낸 편지함에서 삭제가 완료되었습니다');
      close();
    }, 100);
  };

  useEffect(() => {
    setFieldValue('originContent', letter.originContent);
  }, [setFieldValue, letter.originContent]);

  useEffect(() => {
    setIsModifyMode(false);
  }, [open]);

  return (
    <TitleModal title="보낸 편지" open={open} close={close} zIndex={2000}>
      <FormWrapper style={{ margin: '20px 0' }}>
        <SpaceBetweenContainer>
          <FlexContainer>
            {letter.likes === 0 ? <SendIcon /> : <FavoriteIcon />}
            <P>{user.nickname}</P>
          </FlexContainer>
          <Week>
            {toCustomLocale(letter.createdAt)}

            <MenuPopup
              items={[
                {
                  key: 1,
                  label: <div onClick={() => setDeleteModalOpen(true)}>삭제하기</div>,
                },
              ]}
            />
          </Week>
        </SpaceBetweenContainer>

        <SpaceBetweenContainer style={{ marginRight: '28px' }}>
          {letter.likes === 0 ? (
            <div></div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '14px',
                lineHeight: '17px',
                color: COLORS.PoliceBlue,
                minWidth: '24px',
              }}
            >
              {letter.likes}
            </div>
          )}
          <Week>{letter.letterBox?.weeks}주차</Week>
        </SpaceBetweenContainer>

        <DividerLineBlock height={40} />

        {isModifyMode ? (
          <TextArea
            value={values.originContent}
            name="originContent"
            onChange={handleChange}
            placeholder="편지 내용을 입력하세요 :)"
            style={{ height: 432 }}
          />
        ) : (
          <LetterContainer height={473}>
            {isFilteredLetter && (
              <CenterContainer>
                {contentParser(`작성하신 편지의 내용이
UNKNOWN의 운영방침에 적합하지 않아 삭제되었어요. 

부적합 사유: 
${letter.letterFilteredReason || '작성하신 편지에 개인정보나 비속어 등이 포함되어 있습니다.'}


`)}
              </CenterContainer>
            )}
            {contentParser(letter.originContent)}
          </LetterContainer>
        )}
      </FormWrapper>

      <DividerBlock height={20} />

      <CenterContainer style={{ height: '17px' }}>
        {values.originContent.length < 300 && (
          <HelperLabel style={{ marginBottom: '10px' }}>
            최소 300자 이상 작성해주셔야 합니다.
          </HelperLabel>
        )}
      </CenterContainer>

      <CenterContainer>
        {isModifyMode ? (
          <ButtonContainer>
            <CancelButton text={'취소'} onClick={() => setIsModifyMode(false)} />
            <ConfirmButton disabled={!!errors.originContent} text={'수정'} onClick={handleSubmit} />
          </ButtonContainer>
        ) : (
          <ConfirmButton
            disabled={!isModifiable}
            icon={<LetterIcon />}
            text={'수정하기'}
            onClick={() => setIsModifyMode(true)}
          />
        )}
      </CenterContainer>

      <DividerBlock height={20} />

      <SentLetterDeleteModal
        open={deleteModalOpen}
        close={() => setDeleteModalOpen(false)}
        confirm={handleDeleteLetter}
      />
    </TitleModal>
  );
};

export default SentLetterModal;
