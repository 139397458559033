import { useSelector } from '~/hooks/useRedux';

import FindEmailModal from './FindEmailModal';
import FindPasswordModal from './FindPasswordModal';
import GuideModal from './GuideModal';
import LetterListModal from './LetterListModal';
import LetterReplyModal from './LetterReplyModal';
import LetterWriteModal from './LetterWriteModal';
import MenuButtonModal from './MenuButtonModal';
import MyProfileModal from './MyProfileModal';
import NoticeBoardModal from './NoticeBoardModal';
import SignInModal from './SignInModal';
import SignUpModal from './SignUpModal';
import WeeklyTopicBoardModal from './WeeklyTopicBoardModal';

const Modals = () => {
  const weeklyTopicBoard = useSelector((state) => state.modal.weeklyTopicBoard);

  return (
    <>
      <SignInModal />
      <SignUpModal />
      <FindPasswordModal />
      <FindEmailModal />
      <MyProfileModal />

      <NoticeBoardModal />
      {weeklyTopicBoard && <WeeklyTopicBoardModal />}

      <LetterListModal />

      <LetterWriteModal />
      <LetterReplyModal />

      <GuideModal />
      <MenuButtonModal />
    </>
  );
};

export default Modals;
