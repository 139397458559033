import moment from 'moment';
import { useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';

const useReadState = (
  name: string,
): [number[], (readArray: number[]) => void, (id: number, createdAt: Date) => boolean] => {
  const [read, setRead] = useLocalStorage(name, '[]');
  const [readArray, setReadArray] = useState<number[]>(JSON.parse(read));

  const includes = (id: number, createdAt: Date) => {
    const pivot = moment(createdAt);
    const create = moment(new Date());

    pivot.add(7, 'day');

    return readArray.includes(id) || pivot <= create;
  };

  useEffect(() => {
    setRead(JSON.stringify(readArray.filter(onlyUnique)));
  }, [readArray, setRead]);

  return [readArray, setReadArray, includes];
};

export default useReadState;

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
