import { Badge } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useGetReceivedQuery } from '~/api/LetterAPI';
import Letter from '~/classes/Letter';
import LetterRecipient from '~/classes/LetterRecipient';
import Pagination from '~/classes/Pagination';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import { P16 } from '~/components/Styled/Texts';
import Table, { Column } from '~/components/Table';
import COLORS from '~/constants/Colors';
import usePaginationRequest from '~/hooks/usePaginationRequest';

import ReceivedLetterModal from './ReceivedLetterModal';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ReceivedLetter = () => {
  const [pagination, setPage] = usePaginationRequest();
  const [openDetail, setOpenDetail] = useState(false);
  const [letterRecipientId, setLetterRecipientId] = useState<number>();

  const { data = new Pagination<LetterRecipient>(), refetch } = useGetReceivedQuery(pagination);

  const columns: Column<LetterRecipient>[] = [
    {
      title: '주차',
      index: 'letter',
      style: { minWidth: '55px', display: 'flex', justifyContent: 'center' },
      render: (letter: Letter) => <>{letter?.letterBox?.weeks}</>,
    },
    {
      title: '내용',
      index: 'letter',
      style: { width: '100%' },
      render: (letter: Letter, letterRecipient: LetterRecipient) => (
        <SpaceBetweenContainer>
          <P16>{letter?.screenedContent?.replace(/\\n/g, '')?.slice(0, 22)}</P16>
          {!letterRecipient.isRead && <Badge color={COLORS.Saffron} />}
        </SpaceBetweenContainer>
      ),
    },
    {
      title: '보낸 사람',
      index: 'letter',
      style: { minWidth: '95px', display: 'flex', justifyContent: 'center', height: '44px' },
      render: (letter: Letter) => <>{letter?.senderUser?.nickname}</>,
    },
  ];

  useEffect(() => {
    if (!openDetail) refetch();
  }, [refetch, openDetail]);

  return (
    <Wrapper>
      <Table
        dataSource={data.data}
        columns={columns}
        noContentDescription={'아직 받은 편지가 없어요.'}
        onRowClick={(letterRecipient) => {
          setLetterRecipientId(letterRecipient.id);
          setOpenDetail(true);
        }}
        pagination={{
          page: pagination.page,
          limit: pagination.limit,
          total: data?.count,
          onPageClick: (page) => setPage(page),
        }}
      />

      {letterRecipientId && (
        <ReceivedLetterModal
          open={openDetail}
          close={() => setOpenDetail(false)}
          letterRecipientId={letterRecipientId}
        />
      )}
    </Wrapper>
  );
};

export default ReceivedLetter;
