import { Modal, ModalProps } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

import COLORS from '~/constants/Colors';

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    min-height: 250px;
    border: 3px solid ${COLORS.PoliceBlue};
    border-radius: 10px;
  }

  & .ant-modal-body {
    position: relative;
    min-height: 250px;
  }
`;

interface Props extends ModalProps {
  open: boolean;
  close: () => void;
  children: ReactNode;
}

const ConfirmModal = ({ open, close, children, ...props }: Props) => {
  return (
    <StyledModal
      open={open}
      onCancel={close}
      centered
      destroyOnClose
      footer={false}
      closable={false}
      width={450}
      {...props}
    >
      {children}
    </StyledModal>
  );
};
export default ConfirmModal;
