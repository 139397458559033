import { addMonths, format, isSaturday, isSunday, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PaymentType } from '~/classes/Dto/CreatePaymentDto';
import { DraftIcon, MailIcon, MobileIcon } from '~/components/Icons';
import Footer from '~/components/Layout/Footer';
import {
  CenterContainer,
  FlexContainer,
  ShadowContainer,
  SpaceBetweenContainer,
} from '~/components/Styled/Containers';
import { media } from '~/components/Styled/Media';
import COLORS from '~/constants/Colors';
import Path from '~/constants/Path';

const Wrapper = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 40px 80px;

  ${media.tablet`
    margin: 40px 80px;
  `}

  ${media.mobile`
    margin: 24px;
  `}
`;

const Dot = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${COLORS.Saffron};
  margin-right: 40px;

  ${media.mobile`
    width: 16px;
    height: 16px;
    margin-right: 16px;
  `}
`;

const MainTitle = styled.div`
  font-size: 48px;
  line-height: 57px;
  font-weight: 600;

  ${media.mobile`
    font-size: 24px;
    line-height: 29px;
  `}
`;

const BackgroundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0;
  width: 100%;
  background-color: ${COLORS.Saffron30};

  ${media.mobile`
    padding: 40px 0;
  `}
`;

const MainSubTitle = styled.h3`
  display: flex;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  margin-bottom: 16px;

  ${media.tablet`
    font-size: 36px;
    line-height: 43px;
  `}

  ${media.mobile`
    font-size: 24px;
    line-height: 29px;
  `}
`;

const Desc = styled.span`
  font-size: 24px;
  line-height: 29px;
  padding: 0 20px;

  ${media.tablet`
    font-size: 24px;
    line-height: 29px;
  `}

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;

    &.small {
      font-size: 14px;
      line-height: 17px;
    }
  `}
`;

const WhiteBoxWrapper = styled.div`
  display: flex;
  padding: 24px 36px;
  background-color: ${COLORS.White};
  border-radius: 16px;
  margin-top: 40px;

  & > div:not(:last-of-type) {
    margin-right: 80px;
  }

  ${media.tablet`
    flex-direction: column;
    width: calc(100% - 160px);

    & > div:not(:last-of-type) {
      margin-right: 0px;
      margin-bottom: 80px;
    }
  `}

  ${media.mobile`
    flex-direction: column;
    width: calc(100% - 48px);

    & > div:not(:last-of-type) {
      margin-right: 0px;
      margin-bottom: 40px;
    }
  `}
`;

const IconBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const IconTitle = styled.span`
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.Saffron};
  margin-top: 16px;
  margin-bottom: 8px;
  white-space: pre;

  ${media.mobile`
    font-size: 20px;
    line-height: 24px;
  `}
`;

const IconDesc = styled.span`
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  white-space: pre;

  ${media.mobile`
    font-size: 20px;
    line-height: 24px;
  `}
`;

const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 56px 0;

  ${media.tablet`
    padding: 40px 0;
    padding-bottom: 16px;
  `}

  ${media.mobile`
    padding: 40px 0;
    padding-bottom: 16px;
  `}
`;

const ShadowWrapper = styled(ShadowContainer)`
  margin-top: 40px;
  margin-bottom: 16px;

  & > div:first-of-type {
    display: flex;
    margin-bottom: 40px;

    & > div:not(:last-of-type) {
      margin-right: 40px;
    }
  }

  ${media.tablet`
    & > div:first-of-type > div {
      margin-bottom: 16px;
    }

    & > div:first-of-type {
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;

      & > div:not(:last-of-type) {
        margin-right: 0px;
      }
    }
  `}

  ${media.mobile`
    & > div:first-of-type > div {
      margin-bottom: 16px;
    }

    & > div:first-of-type {
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;

      & > div:not(:last-of-type) {
        margin-right: 0px;
      }
    }
  `}
`;

const DayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin: 8px 0;

  &.inner {
    background-color: ${COLORS.Saffron50};
  }

  &.corner-start {
    margin-left: 50%;
    & > div {
      margin-left: -100%;
      background-color: ${COLORS.Saffron50};
      border-radius: 16px;
    }
  }

  &.corner-end {
    margin-right: 50%;
    & > div {
      margin-right: -100%;
      background-color: ${COLORS.Saffron50};
      border-radius: 16px;
    }
  }

  &.corner-start.corner-end {
    & > div {
      min-width: 32px;
    }
  }
`;

const Day = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;

  font-size: 20px;
  line-height: 24px;
  &.now {
    background-color: ${COLORS.Saffron};
    color: ${COLORS.White};
  }
`;

const DotWrapper = styled(FlexContainer)`
  justify-content: right;

  ${media.tablet`
    justify-content: center;
  `}

  ${media.mobile`
    justify-content: center;
  `}
`;

const SmallDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${COLORS.Saffron};
  margin-right: 4px;

  ${media.mobile`
    width: 4px;
    height: 4px;
    border-radius: 2px;
  `}
`;

const CalendarDesc = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  ${media.mobile`
    font-size: 12px;
    line-height: 14px;
  `}
`;

const UnderlineText = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.Saffron};
  text-decoration: underline;
`;

const SubscriptionButton = styled.button`
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: ${COLORS.White};
  border: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  width: 480px;
  height: 80px;

  background: #febe33;
  border-radius: 16px;

  ${media.mobile`
    font-size: 16px;
    line-height: 19px;
    width: 360px;
    height: 48px;
  `}
`;

// temp
const SubscriptionHalfButton = styled.button`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${COLORS.White};
  border: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  width: 220px;
  height: 60px;

  background: #febe33;
  border-radius: 16px;
  margin: 40px 20px 0 20px;

  ${media.mobile`
    font-size: 12px;
    line-height: 16px;
    width: 160px;
    height: 36px;
  `}
`;

const Subscription = () => {
  const navigate = useNavigate();

  const [startDate, endDate] = useMemo(() => {
    const today = startOfDay(new Date());
    return [today, addMonths(today, 1)];
  }, []);

  const handleSubscription = (paymentType: PaymentType) => {
    window.scrollTo({ top: 0 });

    navigate({ pathname: Path.PAYMENT, search: `?paymentType=${paymentType}` });
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Dot /> <MainTitle>언노운 월 정기 구독</MainTitle>
      </TitleWrapper>

      <BackgroundWrapper>
        <MainSubTitle>언노운에서 무엇을 할 수 있나요?</MainSubTitle>
        <Desc>온라인의 편리함은 더하고, 종이 편지의 감동은 그대로</Desc>

        <WhiteBoxWrapper>
          <IconBoxWrapper>
            <MailIcon />

            <IconTitle>매주 받아보는{'\n'}종이편지</IconTitle>
            <IconDesc>매주 화요일, 집으로{'\n'}익명편지를 보내드려요</IconDesc>
          </IconBoxWrapper>

          <IconBoxWrapper>
            <MobileIcon />

            <IconTitle>편지 작성은{'\n'}앱으로 편하게</IconTitle>
            <IconDesc>연필이 아닌, 앱과 웹으로{'\n'}편지를 보낼 수 있어요</IconDesc>
          </IconBoxWrapper>

          <IconBoxWrapper>
            <DraftIcon />

            <IconTitle>언제든 꺼내 읽는{'\n'}온라인 편지함</IconTitle>
            <IconDesc>받은 편지는 모두 저장되어{'\n'}언제든 확인할 수 있어요</IconDesc>
          </IconBoxWrapper>
        </WhiteBoxWrapper>
      </BackgroundWrapper>

      <CalendarWrapper>
        <MainSubTitle>편지는 어떻게 오나요?</MainSubTitle>
        <Desc className="small">언노운은 매주 화요일에 집으로 실제 편지를 발송해드려요.</Desc>
        <Desc className="small">
          오늘 구독을 시작하시면 첫 달은 아래와 같이 사용하실 수 있어요.
        </Desc>

        <ShadowWrapper>
          <div>
            <Calendar
              calendarType="US"
              className={'custom'}
              value={startDate}
              formatMonthYear={(_, date) => format(date, 'M월')}
              formatDay={() => ''}
              tileContent={({ date }) => {
                const inner = startDate <= date && date <= endDate;
                const now = startDate.valueOf() === date.valueOf();

                const cornerStart =
                  (inner && isSunday(date)) || startDate.valueOf() === date.valueOf();
                const cornerEnd =
                  inner && (isSaturday(date) || date.valueOf() === endDate.valueOf());

                return (
                  <DayWrapper
                    className={`${inner ? 'inner' : ''} ${cornerStart ? 'corner-start' : ''} ${
                      cornerEnd ? 'corner-end' : ''
                    }`}
                  >
                    <Day className={`${now ? 'now' : ''}`}>{format(date, 'd')}</Day>
                  </DayWrapper>
                );
              }}
            />
            <Calendar
              calendarType="US"
              className={'custom'}
              value={endDate}
              formatMonthYear={(_, date) => format(date, 'M월')}
              formatDay={() => ''}
              tileContent={({ date }) => {
                const inner = startDate <= date && date <= endDate;
                const now = startDate.valueOf() === date.valueOf();

                const cornerStart = inner && isSunday(date);
                const cornerEnd =
                  inner && (isSaturday(date) || date.valueOf() === endDate.valueOf());

                return (
                  <DayWrapper
                    className={`${inner ? 'inner' : ''} ${cornerStart ? 'corner-start' : ''} ${
                      cornerEnd ? 'corner-end' : ''
                    }`}
                  >
                    <Day className={`${now ? 'now' : ''}`}>{format(date, 'd')}</Day>
                  </DayWrapper>
                );
              }}
            />
          </div>

          <DotWrapper>
            <SmallDot />
            <CalendarDesc style={{ color: COLORS.Saffron, marginRight: 8 }}>
              앱 활성화 기간
            </CalendarDesc>
            <SmallDot style={{ backgroundColor: COLORS.Manatee }} />
            <CalendarDesc style={{ color: COLORS.Manatee, marginRight: 8 }}>
              편지 배송 시작일
            </CalendarDesc>
            <SmallDot style={{ backgroundColor: COLORS.PoliceBlue }} />
            <CalendarDesc style={{ color: COLORS.PoliceBlue }}>다음 결제일</CalendarDesc>
          </DotWrapper>
        </ShadowWrapper>
      </CalendarWrapper>

      <CenterContainer style={{ flexDirection: 'column' }}>
        <CalendarDesc style={{ marginBottom: 40 }}>
          월별로 실물 편지의 배송 횟수가 달라질 수 있어요
        </CalendarDesc>

        <UnderlineText style={{ marginBottom: 16 }}>우선 둘러볼게요</UnderlineText>
        <UnderlineText style={{ marginBottom: 40 }}>서비스에 대해 더 알고 싶어요</UnderlineText>

        <SubscriptionButton onClick={() => handleSubscription(PaymentType.MONTHLY)}>
          구독 시작하기
        </SubscriptionButton>

        <SpaceBetweenContainer>
          <SubscriptionHalfButton onClick={() => handleSubscription(PaymentType.ONE_MONTH)}>
            1달 구독하기
          </SubscriptionHalfButton>
          <SubscriptionHalfButton onClick={() => handleSubscription(PaymentType.TWO_MONTH)}>
            2달 구독하기
          </SubscriptionHalfButton>
        </SpaceBetweenContainer>
      </CenterContainer>

      <Footer color={COLORS.Manatee} />
    </Wrapper>
  );
};

export default Subscription;
