import { Button, Divider, Space } from 'antd';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useDaumPostcodePopup, Address } from 'react-daum-postcode';
import styled from 'styled-components';
import * as Yup from 'yup';

import { SignUpDto } from '~/classes/Dto/SignUpDto';
import { Gender } from '~/classes/User';
import { ConfirmButton } from '~/components/Buttons';
import { Checkbox, HelperLabel, Input, Label, Select } from '~/components/Form';
import TitleModal from '~/components/Modal/TitleModal';
import COLORS from '~/constants/Colors';
import ErrorMessage from '~/constants/ErrorMessage';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { signUp } from '~/reducers/auth';
import { shutter } from '~/reducers/modal';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div`
  color: ${COLORS.PoliceBlue};

  font-size: 16px;
  line-height: 19px;

  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const MiniButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    height: 24px;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 12px;
    margin: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: 120px;
`;

const SmallInput = styled.input`
  border: 1px solid ${COLORS.PoliceBlue};
  border-radius: 5px;
  padding: 4px 8px;

  width: 4em;

  ::placeholder {
    color: ${COLORS.Manatee};
  }
`;

const year = new Date().getFullYear();

enum GenderValue {
  MALE = '남자',
  FEMALE = '여자',
  OTHER = 'OTHER',
}

const SignUpModal = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal.signUp);

  const postcodePopup = useDaumPostcodePopup();

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordCheck: '',
      realname: '',
      phone1: '',
      phone2: '',
      phone3: '',
      birth1: undefined,
      birth2: undefined,
      birth3: undefined,
      gender: undefined,
      nickname: '',
      zipCode: '',
      address: '',
      addressDetail: '',
      code: '',
      agreeChecks: false,
      agreeTermsOfService: false,
      agreePrivacyPolicy: false,
      agreeMarketing: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required().email(),
      password: Yup.string()
        .required()
        .min(8, '비밀번호 조건에 맞지 않습니다.\n(대소문자 + 숫자 + 특수문자 조합 8-32자)')
        .max(32, '비밀번호 조건에 맞지 않습니다.\n(대소문자 + 숫자 + 특수문자 조합 8-32자)')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^\\|#(){}[\];:'",./])[A-Za-z\d@$!%*?&^\\|#(){}[\];:'",./]{8,32}$/,
          '비밀번호 조건에 맞지 않습니다.\n(대소문자 + 숫자 + 특수문자 조합 8-32자)',
        ),
      passwordCheck: Yup.string().required().min(8).max(32),
      realname: Yup.string().required(),
      phone1: Yup.string().required().length(3),
      phone2: Yup.string().required().length(4),
      phone3: Yup.string().required().length(4),
      birth1: Yup.string().required().length(4),
      birth2: Yup.string().required().length(2),
      birth3: Yup.string().required().length(2),
      gender: Yup.mixed<Gender>().oneOf(Object.values(Gender)).required(),
      nickname: Yup.string()
        .required()
        .min(3)
        .max(7)
        .trim()
        .matches(/^[가-힣\s]+$/),
      zipCode: Yup.string().required(),
      address: Yup.string().required(),
      addressDetail: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      const signUpDto = new SignUpDto({
        email: values.email.trim(),
        password: values.password,
        realname: values.realname.trim(),
        phone: `${values.phone1}${values.phone2}${values.phone3}`,
        birthday: `${values.birth1}-${values.birth2}-${values.birth3}`,
        gender: values.gender as unknown as Gender,
        nickname: values.nickname.trim(),
        address: values.address,
        addressDetail: values.addressDetail.trim(),
        zipCode: values.zipCode,
        agreeMarketing: values.agreeMarketing,
      });

      try {
        await dispatch(signUp(signUpDto)).unwrap();

        dispatch(shutter({ signUp: false, signIn: false, findEmail: false, findPassword: false }));

        alert.success('회원가입이 완료되었습니다.');
      } catch (e) {
        if (e.message === ErrorMessage.user.alreadyPhone) {
          alert.error('이미 가입된 전화번호입니다.');
        } else if (e.message === ErrorMessage.user.alreadyExists) {
          alert.error('이미 가입된 이메일입니다.');
        } else if (e.message === ErrorMessage.user.alreadyNickname) {
          alert.error('이미 가입된 닉네임입니다.');
        } else if (e.message === ErrorMessage.user.alreadyUsedCode) {
          alert.error('이미 사용된 코드입니다.');
        } else if (e.message === ErrorMessage.user.notFoundCode) {
          alert.error('잘못된 초대 코드입니다.');
        }
      }
    },
  });

  const handleComplete = (data: Address) => {
    setFieldValue('zipCode', data.zonecode);
    setFieldValue('address', data.address);
  };

  const handlePostPopup = () => {
    postcodePopup({ onComplete: handleComplete });
  };

  return (
    <TitleModal title="회원가입" open={open} close={() => dispatch(shutter({ signUp: false }))}>
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              label={'아이디'}
              value={values.email}
              onChange={handleChange}
              name="email"
              placeholder="이메일 주소를 입력해주세요."
            />
          </InputWrapper>

          {values.email.length > 0 && errors.email && (
            <InputWrapper>
              <Label></Label>
              <HelperLabel>이메일 형식으로 입력해주세요.</HelperLabel>
            </InputWrapper>
          )}

          <InputWrapper>
            <Input
              label={'비밀번호'}
              value={values.password}
              onChange={handleChange}
              name="password"
              placeholder="영문 대소문자 + 숫자 + 특수문자 조합 8-32자"
              inputType={'password'}
            />
          </InputWrapper>

          <InputWrapper>
            <Input
              value={values.passwordCheck}
              onChange={handleChange}
              name="passwordCheck"
              placeholder="비밀번호를 다시 한번 입력해주세요."
              inputType={'password'}
            />
          </InputWrapper>

          {values.password.length > 0 &&
            values.passwordCheck.length > 0 &&
            (errors.password || values.password !== values.passwordCheck) && (
              <InputWrapper>
                <Label></Label>
                <HelperLabel>{errors.password || '비밀번호가 일치하지 않습니다.'}</HelperLabel>
              </InputWrapper>
            )}

          <InputWrapper>
            <Input
              label={'성함'}
              value={values.realname}
              onChange={handleChange}
              name="realname"
              placeholder="성함"
            />
          </InputWrapper>

          <InputWrapper>
            <Label>휴대전화</Label>

            <Space>
              <SmallInput
                value={values.phone1}
                onChange={handleChange}
                name="phone1"
                placeholder="000"
                maxLength={3}
              />
              <SmallInput
                value={values.phone2}
                onChange={handleChange}
                name="phone2"
                placeholder="0000"
                maxLength={4}
              />
              <SmallInput
                value={values.phone3}
                onChange={handleChange}
                name="phone3"
                placeholder="0000"
                maxLength={4}
              />
            </Space>
          </InputWrapper>

          <InputWrapper>
            <Label>생년월일</Label>
            <Space>
              <Select
                value={values.birth1}
                onChange={(v) => setFieldValue('birth1', v)}
                options={Array.from(Array(100).keys())
                  .map((_, i) => ({
                    label: `${i + year - 100}`,
                    value: `${i + year - 100}`,
                  }))
                  .reverse()}
                placeholder="YYYY"
                dropdownMatchSelectWidth={false}
              />

              <Select
                value={values.birth2}
                onChange={(v) => setFieldValue('birth2', v)}
                options={Array.from(Array(12).keys()).map((_, i) => ({
                  label: `${i + 1}`.padStart(2, '0'),
                  value: `${i + 1}`.padStart(2, '0'),
                }))}
                placeholder="MM"
                dropdownMatchSelectWidth={false}
              />

              <Select
                value={values.birth3}
                onChange={(v) => setFieldValue('birth3', v)}
                options={Array.from(Array(31).keys()).map((_, i) => ({
                  label: `${i + 1}`.padStart(2, '0'),
                  value: `${i + 1}`.padStart(2, '0'),
                }))}
                placeholder="DD"
                dropdownMatchSelectWidth={false}
              />
            </Space>
          </InputWrapper>

          <InputWrapper>
            <Label>성별</Label>

            <Select
              value={values.gender}
              onChange={(v) => setFieldValue('gender', v)}
              options={Object.values(Gender).map((gender) => ({
                label: GenderValue[gender],
                value: gender,
              }))}
              placeholder="성별"
              dropdownMatchSelectWidth={false}
            />
          </InputWrapper>

          <InputWrapper>
            <Input
              label={'닉네임'}
              value={values.nickname}
              onChange={handleChange}
              name="nickname"
              placeholder="3-7자 이내의 한글"
            />
          </InputWrapper>

          {values.nickname.length > 0 && errors.nickname && (
            <InputWrapper>
              <Label></Label>

              <HelperLabel>3-7자 이내의 한글을 입력해주세요.</HelperLabel>
            </InputWrapper>
          )}

          <InputWrapper>
            <Label>배송지</Label>

            <MiniButtonWrapper>
              <ConfirmButton type="button" onClick={handlePostPopup}>
                주소검색
              </ConfirmButton>
            </MiniButtonWrapper>
            <div onClick={handlePostPopup} style={{ margin: 0 }}>
              <SmallInput
                onClick={handlePostPopup}
                value={values.zipCode}
                onChange={handleChange}
                name="zipCode"
                placeholder="우편번호"
                disabled
                type="number"
                style={{ width: '6em', marginLeft: '16px', cursor: 'pointer' }}
              />
            </div>
          </InputWrapper>

          <InputWrapper>
            <div onClick={handlePostPopup}>
              <Input
                value={values.address}
                onChange={handleChange}
                name="address"
                placeholder="주소"
                disabled
                style={{ cursor: 'pointer' }}
              />
            </div>
          </InputWrapper>

          <InputWrapper>
            <Input
              value={values.addressDetail}
              onChange={handleChange}
              name="addressDetail"
              placeholder="상세주소"
            />
          </InputWrapper>

          <InputWrapper>
            <Label></Label>
            <Checkbox
              checked={values.agreeChecks}
              onChange={(checked) => {
                setFieldValue('agreeChecks', checked);
                setFieldValue('agreeTermsOfService', checked);
                setFieldValue('agreePrivacyPolicy', checked);
                setFieldValue('agreeMarketing', checked);
              }}
              label="전체동의"
            />
          </InputWrapper>

          <Divider style={{ margin: '12px 0' }} />

          <InputWrapper>
            <Label></Label>
            <Checkbox
              checked={values.agreeTermsOfService}
              onChange={(checked) => {
                setFieldValue('agreeTermsOfService', checked);
              }}
              label="이용약관 (필수)"
            />
          </InputWrapper>
          <InputWrapper>
            <Label></Label>
            <Button
              type="text"
              href="https://letterunknown-legalsharing.s3.ap-northeast-2.amazonaws.com/%E1%84%8B%E1%85%B5%E1%86%A8%E1%84%86%E1%85%A7%E1%86%BC+%E1%84%8B%E1%85%AE%E1%84%91%E1%85%A7%E1%86%AB+UNKNOWN+%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%80%E1%85%AA%E1%86%AB_v2.2pg.pdf"
              target="_black"
              style={{ margin: '-20px 0 -10px 18px' }}
            >
              내용보기
            </Button>
          </InputWrapper>

          <InputWrapper>
            <Label></Label>
            <Checkbox
              checked={values.agreePrivacyPolicy}
              onChange={(checked) => {
                setFieldValue('agreePrivacyPolicy', checked);
              }}
              label="개인정보처리지침 (필수)"
            />
          </InputWrapper>
          <InputWrapper>
            <Label></Label>
            <Button
              type="text"
              href="https://letterunknown-legalsharing.s3.ap-northeast-2.amazonaws.com/(1%E1%84%8E%E1%85%A1)+%E1%84%8B%E1%85%B5%E1%86%A8%E1%84%86%E1%85%A7%E1%86%BC+%E1%84%8B%E1%85%AE%E1%84%91%E1%85%A7%E1%86%AB+UNKNOWN+%E1%84%80%E1%85%A2%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9%E1%84%8E%E1%85%A5%E1%84%85%E1%85%B5%E1%84%87%E1%85%A1%E1%86%BC%E1%84%8E%E1%85%B5%E1%86%B7_%E1%84%80%E1%85%A2%E1%84%8C%E1%85%A5%E1%86%BC.pdf"
              target="_black"
              style={{ margin: '-20px 0 -10px 18px' }}
            >
              내용보기
            </Button>
          </InputWrapper>

          <InputWrapper>
            <Label></Label>
            <Checkbox
              checked={values.agreeMarketing}
              onChange={(checked) => {
                setFieldValue('agreeMarketing', checked);
              }}
              label="마케팅 정보수신 동의 (선택)"
            />
          </InputWrapper>

          <ButtonWrapper>
            <ConfirmButton
              type="submit"
              disabled={
                !values.agreeTermsOfService ||
                !values.agreePrivacyPolicy ||
                Object.keys(errors).length !== 0
              }
            >
              가입하기
            </ConfirmButton>
          </ButtonWrapper>
        </form>
      </Wrapper>
    </TitleModal>
  );
};

export default SignUpModal;
