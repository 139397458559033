import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Layout from './components/Layout';
import Path from './constants/Path';
import { useDispatch, useSelector } from './hooks/useRedux';
import { load } from './reducers/auth';
import EmailVerification from './routes/EmailVerification';
import Home from './routes/Home';
import Letter from './routes/Letter';
import Payment from './routes/Payment';
import PaymentSuccess from './routes/Payment/Success';
import Subscription from './routes/Subscription';
import Ticket from './routes/Ticket';

const Router = () => {
  const dispatch = useDispatch();

  const isTokenLoaded = useSelector((state) => state.auth.isTokenLoaded);
  const isSignIn = useSelector((state) => state.auth.isSignIn);

  useEffect(() => {
    dispatch(load());
  }, [dispatch]);

  if (!isTokenLoaded) {
    return <></>;
  }

  return (
    <Routes>
      <Route element={<Layout />}>
        {/* 로그인이 꼭 필요하지 않은 경우 */}
        <Route path={Path.HOME} element={<Home />} />
        <Route path={Path.TICKET} element={<Ticket />} />
      </Route>

      <Route element={<ProtectedRoute isSignIn={isSignIn} />}>
        {/* 로그인이 꼭 필요한 경우 */}
        <Route path={Path.LETTER} element={<Letter />} />
        <Route path={Path.SUBSCRIPTION} element={<Subscription />} />
        <Route path={Path.PAYMENT} element={<Payment />} />
        <Route path={Path.PAYMENT_SUCCESS} element={<PaymentSuccess />} />
      </Route>

      <Route path={Path.EMAIL_VERIFICATION} element={<EmailVerification />} />
      <Route path={'/*'} element={<Navigate to={Path.HOME} />} />
    </Routes>
  );
};

export default Router;
