import { Dropdown } from 'antd';
import styled from 'styled-components';

import { useLazyGetSendableQuery } from '~/api/LetterAPI';
import { getWeeksDetail } from '~/helper/util';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { signOut } from '~/reducers/auth';
import { shutter } from '~/reducers/modal';

import { CalendarMonthIcon } from '../Icons';
import { media } from '../Styled/Media';

const Wrapper = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: center;
  padding: 0;
  height: 110px;
  width: 100%;
  padding-right: 20px;
`;

const TextButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  font-size: 20px;
  line-height: 24px;
  padding: 0;
  margin: auto 20px;

  ${media.mobile`
    font-size: 14px;
    line-height: 17px;
    margin: auto 10px;
  `}
`;

const LetterHeader = () => {
  const isSignIn = useSelector((state) => state.auth.isSignIn);
  const weeks = useSelector((state) => state.letter.letterBox.weeks);
  const [getSendable, { data }] = useLazyGetSendableQuery();

  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const handleMyProfile = () => {
    dispatch(shutter({ myProfile: true }));
  };

  // const handleMenuButton = () => {
  //   dispatch(shutter({ menuButton: true }));
  // };

  const handleSignIn = () => {
    dispatch(shutter({ signIn: true }));
  };

  return (
    <Wrapper>
      {isSignIn ? (
        <>
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: 1,
                  label: (
                    <>
                      오늘은 {weeks}주차 ({getWeeksDetail(weeks)})입니다
                    </>
                  ),
                },
                { key: 2, type: 'divider' },
                { key: 3, label: <>내게 주어진 작성 기회 (새 편지 {data?.weekly ? 1 : 0}개)</> },
              ],
            }}
          >
            <CalendarMonthIcon onClick={() => getSendable()} />
          </Dropdown>

          {/* <TextButton onClick={handleMenuButton}>버튼 모음</TextButton> */}
          <TextButton onClick={handleMyProfile}>마이페이지</TextButton>
          <TextButton onClick={handleSignOut}>로그아웃</TextButton>
        </>
      ) : (
        <>{/* <TextButton onClick={handleSignIn}>회원가입/로그인</TextButton> */}</>
      )}
    </Wrapper>
  );
};

export default LetterHeader;
