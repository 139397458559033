import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { SerializedError } from '@reduxjs/toolkit';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);

    return Promise.reject(error);
  },
);

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    SerializedError
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await instance({ url, method, data, params });

      return { data: result.data };
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;

      return Promise.reject(new Error(axiosError.response?.data?.message || axiosError.message));
    }
  };

export default instance;
