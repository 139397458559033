// https://www.color-name.com/

const COLORS = {
  White: '#ffffff',
  Black: '#000000',
  Black50: 'rgba(0, 0, 0, 0.5)',
  PoliceBlue: '#3F4C5F',
  PoliceBlue80: 'rgba(63, 76, 95, 0.8)',
  PoliceBlue50: 'rgba(63, 76, 95, 0.5)',
  PoliceBlue10: 'rgba(63, 76, 95, 0.1)',
  PoliceBlue05: 'rgba(63, 76, 95, 0.05)',
  Manatee: '#9AA0AA',
  Saffron: '#FEBE33',
  Saffron50: '#FEBE3380',
  Saffron30: '#FEBE334d',
  BrightGray: '#ECEDEF',
  LightSilver: '#D9D9D9',
};

export default COLORS;
