import { Modal, ModalProps } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

import COLORS from '~/constants/Colors';

import { CloseYellowIcon, EmailIcon, LetterIcon } from '../Icons';
import { SpaceBetweenContainer } from '../Styled/Containers';

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    position: relative;
    min-height: 250px;
    border: 3px solid ${COLORS.PoliceBlue};
    border-radius: 10px;
    padding: 47px 0 0 0;
  }

  & .ant-modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px;
    height: 770px;
    overflow-y: auto;
    font-size: 20px;
    line-height: 24px;
  }
`;

const TitleWrapper = styled.div`
  color: ${COLORS.White};
  position: absolute;
  top: -1px;
  left: -1px;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 2px);
  height: 48px;
  padding: 12px;
  background-color: ${COLORS.PoliceBlue};
  border-radius: 3px 3px 0 0;
`;

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Tab = styled.div`
  cursor: pointer;
  color: ${COLORS.White};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: calc(50%);
  height: 48px;
  background-color: ${COLORS.PoliceBlue50};

  & svg {
    margin-right: 8px;
  }

  &.left {
    border-radius: 0px 0px 10px 0px;
  }

  &.right {
    border-radius: 0px 0px 0px 10px;
  }

  &.active {
    background-color: ${COLORS.PoliceBlue};
  }
`;

interface Props extends ModalProps {
  title: string;
  activeTab: 'left' | 'right';
  leftTitle: string;
  rightTitle: string;
  leftClick: () => void;
  rightClick: () => void;
  open: boolean;
  close: () => void;
  children: ReactNode;
}

const TabModal = ({
  title,
  activeTab,
  leftTitle,
  rightTitle,
  leftClick,
  rightClick,
  open,
  close,
  children,
  ...props
}: Props) => {
  return (
    <StyledModal
      open={open}
      onCancel={close}
      centered
      destroyOnClose
      footer={false}
      closable={false}
      {...props}
    >
      <TitleWrapper>
        <div></div>
        {title}
        <CloseYellowIcon onClick={close} />
      </TitleWrapper>
      <TabWrapper>
        <Tab onClick={leftClick} className={`left ${activeTab === 'left' ? ' active' : ''}`}>
          {activeTab === 'left' ? <LetterIcon /> : <EmailIcon />}
          {leftTitle}
        </Tab>
        <Tab onClick={rightClick} className={`right ${activeTab === 'right' ? ' active' : ''}`}>
          {activeTab === 'right' ? <LetterIcon /> : <EmailIcon />}
          {rightTitle}
        </Tab>
      </TabWrapper>

      {children}
    </StyledModal>
  );
};

export default TabModal;
