import Letter from './Letter';
import User from './User';

export default class LetterRecipient {
  id: number;
  letterId: number;
  letter?: Letter;
  recipientUserId: number;
  recipientUser?: User;
  isRead: boolean;
  isLike: boolean;
  isSentReply: boolean;
  isDelete: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(letterRecipient: LetterRecipient) {
    this.id = letterRecipient.id;
    this.letterId = letterRecipient.letterId;
    this.letter = letterRecipient.letter && new Letter(letterRecipient.letter);
    this.recipientUserId = letterRecipient.recipientUserId;
    this.recipientUser = letterRecipient.recipientUser && new User(letterRecipient.recipientUser);
    this.isRead = letterRecipient.isRead;
    this.isLike = letterRecipient.isLike;
    this.isSentReply = letterRecipient.isSentReply;
    this.isDelete = letterRecipient.isDelete;
    this.createdAt = new Date(letterRecipient.createdAt);
    this.updatedAt = new Date(letterRecipient.updatedAt);
  }
}
