import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useGetSentQuery } from '~/api/LetterAPI';
import Letter, { LetterStatus } from '~/classes/Letter';
import LetterBox from '~/classes/LetterBox';
import Pagination from '~/classes/Pagination';
import { ErrorYellowIcon, FavoriteIcon } from '~/components/Icons';
import { P16 } from '~/components/Styled/Texts';
import Table, { Column } from '~/components/Table';
import usePaginationRequest from '~/hooks/usePaginationRequest';

import SentLetterModal from './SentLetterModal';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SentLetter = () => {
  const [pagination, setPage] = usePaginationRequest();
  const [openDetail, setOpenDetail] = useState(false);
  const [letter, setLetter] = useState<Letter>();

  const { data = new Pagination<Letter>(), refetch } = useGetSentQuery(pagination);

  const columns: Column<Letter>[] = [
    {
      title: '주차',
      index: 'letterBox',
      style: {
        display: 'flex',
        minWidth: '55px',
        justifyContent: 'center',
        alignItems: 'center',
      },
      render: (letterBox: LetterBox) => <>{letterBox?.weeks}</>,
    },
    {
      title: '내용',
      index: 'originContent', // CHECK: 검수된 내용으로 표시?
      style: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },
      render: (originContent: string) => (
        <P16>{originContent.replace(/\\n/g, '')?.slice(0, 22)}</P16>
      ),
    },
    {
      title: <FavoriteIcon />,
      index: 'likes',
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '55px',
        height: '44px',
      },
      render: (likes: number, letter: Letter) => (
        <>
          {letter.letterBox?.isClosed &&
          (letter.letterStatus === LetterStatus.SCREENING_FAILURE ||
            letter.letterStatus === LetterStatus.SCREENING_OPERATOR_FAILURE) ? (
            <ErrorYellowIcon />
          ) : (
            likes
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!openDetail) refetch();
  }, [refetch, openDetail]);

  return (
    <Wrapper>
      <Table
        dataSource={data.data}
        columns={columns}
        noContentDescription={'아직 보낸 편지가 없어요.'}
        onRowClick={(letter) => {
          setLetter(letter);
          setOpenDetail(true);
        }}
        pagination={{
          page: pagination.page,
          limit: pagination.limit,
          total: data?.count,
          onPageClick: (page) => setPage(page),
        }}
      />

      {letter && (
        <SentLetterModal open={openDetail} close={() => setOpenDetail(false)} letter={letter} />
      )}
    </Wrapper>
  );
};

export default SentLetter;
