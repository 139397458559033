export class MyProfileDto {
  nickname?: string;
  realname?: string;
  phone?: string;
  recipient?: string;
  address?: string;
  addressDetail?: string;
  zipCode?: string;
  pushToken?: string;
  servicePush?: boolean;
  letterPush?: boolean;
  eventPush?: boolean;
  SMSPush?: boolean;
  emailPush?: boolean;

  constructor(myProfileDto: MyProfileDto) {
    this.nickname = myProfileDto.nickname;
    this.realname = myProfileDto.realname;
    this.phone = myProfileDto.phone;
    this.recipient = myProfileDto.recipient;
    this.address = myProfileDto.address;
    this.addressDetail = myProfileDto.addressDetail;
    this.zipCode = myProfileDto.zipCode;
    this.pushToken = myProfileDto.pushToken;
    this.servicePush = myProfileDto.servicePush;
    this.letterPush = myProfileDto.letterPush;
    this.eventPush = myProfileDto.eventPush;
    this.SMSPush = myProfileDto.SMSPush;
    this.emailPush = myProfileDto.emailPush;
  }
}
