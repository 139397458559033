import { Navigate, Outlet } from 'react-router-dom';

import Layout from '~/components/Layout';
import Path from '~/constants/Path';

interface Props {
  isSignIn: boolean;
  withLayout?: boolean;
  redirectPath?: ValueOf<typeof Path>;
}

const ProtectedRoute = ({ isSignIn, withLayout = true, redirectPath = Path.HOME }: Props) => {
  if (!isSignIn) {
    return <Navigate to={redirectPath} replace />;
  }

  if (withLayout) {
    return <Layout />;
  } else {
    return <Outlet />;
  }
};

export default ProtectedRoute;
