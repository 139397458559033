import { useState } from 'react';
import styled from 'styled-components';

import TabModal from '~/components/Modal/TabModal';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { shutter } from '~/reducers/modal';

import BestLetterList from './BestLetterList';
import NoticeList from './NoticeList';

const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
`;

const NoticeBoardModal = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal.noticeBoard);
  const [activeTab, setActiveTab] = useState<'left' | 'right'>('left');

  return (
    <TabModal
      title="UNKNOWN 게시판"
      activeTab={activeTab}
      leftTitle={'모두의 편지'}
      rightTitle={'공지사항'}
      leftClick={() => setActiveTab('left')}
      rightClick={() => setActiveTab('right')}
      open={open}
      close={() => dispatch(shutter({ noticeBoard: false }))}
    >
      <Wrapper>
        {activeTab === 'left' && <BestLetterList />}
        {activeTab === 'right' && <NoticeList />}
      </Wrapper>
    </TabModal>
  );
};

export default NoticeBoardModal;
