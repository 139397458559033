import { Divider, DividerProps } from 'antd';

interface Props extends DividerProps {
  height: number;
}

export const DividerBlock = ({ height, ...props }: Props) => (
  <Divider {...props} style={{ margin: `${height / 2}px 0`, borderTop: 0 }} />
);

export const DividerLineBlock = ({ height, ...props }: Props) => (
  <Divider {...props} style={{ margin: `${height / 2}px 0` }} />
);
