import Letter from './Letter';
import { ServiceType } from './User';

export default class LetterBox {
  id: number;
  weeks: number;
  serviceType: ServiceType;
  isClosed: boolean;
  bestLetterId?: number;
  bestLetter?: Letter;
  letters?: Letter[];
  createdAt: Date;
  updatedAt: Date;

  constructor(letterBox: LetterBox) {
    this.id = letterBox.id;
    this.weeks = letterBox.weeks;
    this.serviceType = letterBox.serviceType;
    this.isClosed = letterBox.isClosed;
    this.bestLetterId = letterBox.bestLetterId;
    this.bestLetter = letterBox.bestLetter && new Letter(letterBox.bestLetter);
    this.letters = letterBox.letters && letterBox.letters.map((letter) => new Letter(letter));
    this.createdAt = new Date(letterBox.createdAt);
    this.updatedAt = new Date(letterBox.updatedAt);
  }
}
