import { createSlice } from '@reduxjs/toolkit';

export interface UserState {}

const initialState: UserState = {};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {},
});

export default user.reducer;
