import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import * as Yup from 'yup';

import { useLazyPatchNewPasswordQuery } from '~/api/UserAPI';
import { HelperLabel, Input } from '~/components/Form';
import { CancelButton, ConfirmButton } from '~/components/Modal/Buttons';
import ConfirmModal from '~/components/Modal/ConfirmModal';
import { ButtonContainer } from '~/components/Modal/Containers';

interface Props {
  open: boolean;
  close: () => void;
}

const PasswordModal = ({ open, close }: Props) => {
  const [patchPassword] = useLazyPatchNewPasswordQuery();
  const alert = useAlert();

  const { values, errors, handleChange, handleSubmit, resetForm, setFieldValue } = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordCheck: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required().min(8).max(32),
      newPassword: Yup.string()
        .required()
        .min(8, '비밀번호 조건에 맞지 않습니다. (대소문자 + 숫자 + 특수문자 조합 8-32자)')
        .max(32, '비밀번호 조건에 맞지 않습니다. (대소문자 + 숫자 + 특수문자 조합 8-32자)')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^\\|#(){}[\];:'",./])[A-Za-z\d@$!%*?&^\\|#(){}[\];:'",./]{8,32}$/,
          '비밀번호 조건에 맞지 않습니다. (대소문자 + 숫자 + 특수문자 조합 8-32자)',
        ),
      newPasswordCheck: Yup.string().required().min(8).max(32),
    }),
    onSubmit: async (values) => {
      if (values.newPassword !== values.newPasswordCheck) {
        return;
      }

      const { data } = await patchPassword(values);

      if (data?.success) {
        resetForm();
        alert.success('비밀번호가 성공적으로 변경되었습니다.');
        close();
      } else {
        alert.success('비밀번호가 틀렸습니다.');
      }
    },
  });

  return (
    <ConfirmModal open={open} close={close}>
      <form onSubmit={handleSubmit}>
        <Input
          label={'기존 비밀번호'}
          value={values.password}
          onChange={handleChange}
          name="password"
          placeholder="기존 비밀번호"
          inputType={'password'}
        />
        <Input
          label={'새 비밀번호'}
          value={values.newPassword}
          onChange={handleChange}
          name="newPassword"
          placeholder="새 비밀번호"
          inputType={'password'}
        />
        <Input
          label={'새 비밀번호 확인'}
          value={values.newPasswordCheck}
          onChange={handleChange}
          name="newPasswordCheck"
          placeholder="새 비밀번호 확인"
          inputType={'password'}
        />

        <div style={{ margin: '4px 0 4px 96px', height: '48px' }}>
          {(errors.newPassword || values.newPassword !== values.newPasswordCheck) &&
            values.newPassword.length > 0 && (
              <HelperLabel>
                {errors.newPassword || '새로운 비밀번호가 일치하지 않습니다.'}
              </HelperLabel>
            )}
        </div>

        <ButtonContainer>
          <CancelButton type={'button'} text={'취소'} onClick={close} />
          <ConfirmButton
            disabled={Object.keys(errors).length !== 0}
            type={'submit'}
            text={'확인'}
          />
        </ButtonContainer>
      </form>
    </ConfirmModal>
  );
};
export default PasswordModal;
