import { Badge } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { useGetBoardQuery } from '~/api/BoardAPI';
import { Board, BoardType } from '~/classes/Board';
import LetterBox from '~/classes/LetterBox';
import Pagination from '~/classes/Pagination';
import TitleModal from '~/components/Modal/TitleModal';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import { P16 } from '~/components/Styled/Texts';
import Table, { Column } from '~/components/Table';
import COLORS from '~/constants/Colors';
import { dateFormatting } from '~/helper/util';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import useReadState from '~/hooks/useReadArray';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { shutter } from '~/reducers/modal';

import WeeklyTopicModal from './WeeklyTopicModal';

const Wrapper = styled.div`
  display: flex;
  height: 770px;
  width: 100%;
`;

const WeeklyTopicBoardModal = () => {
  const [pagination, setPage] = usePaginationRequest();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal.weeklyTopicBoard);
  const [openDetail, setOpenDetail] = useState(false);
  const [board, setBoard] = useState<Board>();
  const [isReadArray, setIsReadArray, includes] = useReadState('weeklyTopic');

  const { data = new Pagination<Board>() } = useGetBoardQuery({
    ...pagination,
    boardType: BoardType.WEEKLY_TOPIC,
  });

  const columns: Column<Board>[] = [
    {
      title: '주차',
      index: 'letterBox',
      style: { minWidth: '55px', display: 'flex', justifyContent: 'center' },
      render: (letterBox: LetterBox) => <>{letterBox?.weeks}</>,
    },
    {
      title: '제목',
      index: 'title',
      style: { width: '100%' },
      render: (title, board) => (
        <SpaceBetweenContainer>
          <P16>{title}</P16>
          {!includes(board.id, board.createdAt) && <Badge color={COLORS.Saffron} />}
        </SpaceBetweenContainer>
      ),
    },
    {
      title: '업로드 날짜',
      index: 'createdAt',
      style: { minWidth: '95px', display: 'flex', justifyContent: 'center' },
      render: (createAt: Date) => <>{dateFormatting(createAt)}</>,
    },
  ];

  return (
    <TitleModal
      title="금주의 주제"
      open={open}
      close={() => dispatch(shutter({ weeklyTopicBoard: false }))}
      hideArrowIcon
    >
      <Wrapper>
        <Table
          dataSource={data.data}
          columns={columns}
          noContentDescription={'아직 금주의 주제가 없어요.'}
          onRowClick={(board) => {
            setBoard(board);
            setOpenDetail(true);
            setIsReadArray([...isReadArray, board.id]);
          }}
          pagination={{
            page: pagination.page,
            limit: pagination.limit,
            total: data?.count,
            onPageClick: (page) => setPage(page),
          }}
        />
      </Wrapper>

      {board && (
        <WeeklyTopicModal open={openDetail} close={() => setOpenDetail(false)} board={board} />
      )}
    </TitleModal>
  );
};

export default WeeklyTopicBoardModal;
