import 'antd/dist/antd.css';
import React from 'react';
import { Provider as AlertProvider } from 'react-alert';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '~/reducers/store';

import Router from './Router';
import AlertTemplate from './components/AlertTemplate';
import { GlobalStyle } from './components/GlobalStyle';

const options = {
  template: AlertTemplate,
  timeout: 0,
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <Provider store={store}>
      <AlertProvider {...options}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </AlertProvider>
    </Provider>
  </React.StrictMode>,
);
