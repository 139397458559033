import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLazyPatchEmailVerificationQuery } from '~/api/UserAPI';
import Path from '~/constants/Path';
import { useDispatch } from '~/hooks/useRedux';
import { load } from '~/reducers/auth';

const EmailVerification = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');
  const code = searchParams.get('code');

  const [patchEmailVerification] = useLazyPatchEmailVerificationQuery();

  const init = async () => {
    if (email && code && email.length > 0 && code.length > 0) {
      const { isSuccess, isError } = await patchEmailVerification({
        email,
        emailVerificationCode: code,
      });

      if (isSuccess) {
        alert.success('인증에 성공했습니다!');
        setTimeout(() => dispatch(load()), 10);
      }

      if (isError) {
        alert.error('잘못된 접근입니다.');
      }
    } else {
      alert.error('잘못된 접근입니다.');
    }

    navigate(Path.HOME);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default EmailVerification;
