import { createApi } from '@reduxjs/toolkit/query/react';

import CreatePaymentDto, { PaymentType } from '~/classes/Dto/CreatePaymentDto';
import { FindEmailDto } from '~/classes/Dto/FindEmailDto';
import { FindPasswordDto } from '~/classes/Dto/FindPasswordDto';
import { MyProfileDto } from '~/classes/Dto/MyProfileDto';
import { SignInDto } from '~/classes/Dto/SignInDto';
import { SignUpDto } from '~/classes/Dto/SignUpDto';
import Token from '~/classes/Token';
import User from '~/classes/User';
import axios, { axiosBaseQuery } from '~/helper/axios';

const signIn = async (signInDto: SignInDto): Promise<{ user: User; token: Token }> => {
  const { data } = await axios.post<{ user: User; token: Token }>('/auth/sign-in', signInDto);

  return {
    user: new User(data.user),
    token: data.token,
  };
};

const signUp = async (signUpDto: SignUpDto): Promise<{ user: User; token: Token }> => {
  const { data } = await axios.post<{ user: User; token: Token }>('/auth/sign-up', signUpDto);

  return {
    user: new User(data.user),
    token: data.token,
  };
};

const load = async (): Promise<User> => {
  const { data } = await axios.get<User>('/auth/load');

  return new User(data);
};

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    postFindEmail: builder.query<{ email: string }, FindEmailDto>({
      query: (data) => ({ url: '/user/find-email', method: 'post', data }),
    }),
    postFindPassword: builder.query<void, FindPasswordDto>({
      query: (data) => ({ url: '/user/find-password', method: 'post', data }),
    }),
    postNicknameCheck: builder.query<{ success: boolean }, { nickname: string }>({
      query: (data) => ({ url: '/user/nickname-check', method: 'post', data }),
    }),
    patchMy: builder.query<void, MyProfileDto>({
      query: (data) => ({ url: '/user/my', method: 'patch', data }),
    }),
    postSubscription: builder.mutation<{ orderCode: string }, { paymentType: PaymentType }>({
      query: (data) => ({ url: '/user/subscription', method: 'post', data }),
    }),
    postSubscriptionCallback: builder.mutation<{ success: boolean }, CreatePaymentDto>({
      query: (data) => ({ url: '/user/subscription/callback', method: 'post', data }),
    }),
    patchNewPassword: builder.query<
      { success: Boolean },
      { password: string; newPassword: string }
    >({
      query: (data) => ({ url: '/user/password', method: 'patch', data }),
    }),
    patchEmailVerification: builder.query<
      { success: Boolean },
      { email: string; emailVerificationCode: string }
    >({
      query: (data) => ({ url: '/user/email-verification', method: 'patch', data }),
    }),
  }),
});

export const {
  useLazyPostFindEmailQuery,
  useLazyPostFindPasswordQuery,
  useLazyPostNicknameCheckQuery,
  useLazyPatchMyQuery,
  usePostSubscriptionMutation,
  usePostSubscriptionCallbackMutation,
  useLazyPatchNewPasswordQuery,
  useLazyPatchEmailVerificationQuery,
} = userApi;

export default {
  signIn,
  signUp,
  load,
};
