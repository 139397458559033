import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import Footer from '~/components/Layout/Footer';
import COLORS from '~/constants/Colors';
import Path from '~/constants/Path';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { shutter } from '~/reducers/modal';

import Door from './Door';
import IntroductionModal from './IntroductionModal';
import TicketModal from './TicketModal';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 1920px;
  max-width: 100%;
  min-height: calc(100vh - 110px);
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
`;

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isSignIn = useSelector((state) => state.auth.isSignIn);
  const [introductionModalOpen, setIntroductionModalOpen] = useState(false);
  const [ticketModalOpen, setTicketModalOpen] = useState(false);

  const handleIntroduction = () => {
    console.log('handleIntroduction');
    setIntroductionModalOpen(true);
  };

  const handleTicket = () => {
    // navigate(Path.TICKET);

    window.open('https://linktr.ee/letterunknown', '_blank');
  };

  const handleMyPage = () => {
    console.log('handleMyPage');

    if (isSignIn) {
      dispatch(shutter({ myProfile: true }));
    } else {
      // dispatch(shutter({ signIn: true }));
      window.open('https://linktr.ee/letterunknown', '_blank');
    }
  };

  const handleRoom = () => {
    console.log('handleRoom');

    if (isSignIn) {
      navigate(Path.LETTER);
    } else {
      // dispatch(shutter({ signIn: true }));
      window.open('https://linktr.ee/letterunknown', '_blank');
    }
  };

  useEffect(() => {
    const orderId = searchParams.get('order_id');
    const orderCode = searchParams.get('order_code');
    const status = searchParams.get('status');

    if (orderId && orderCode && status) {
      window.location.replace(`letter://?${searchParams.toString()}`);
    }
  }, [searchParams]);

  return (
    <Wrapper>
      <Door
        src={'/img/introduction.png'}
        onClick={handleIntroduction}
        style={{ top: '30%', left: '12.5%', width: '12.5%' }}
      />

      <Door
        src={'/img/ticket.png'}
        onClick={handleTicket}
        style={{ top: '10%', left: '35%', width: '8.5%' }}
      />

      <Door
        src={'/img/my_page.png'}
        onClick={handleMyPage}
        style={{ top: '18%', left: '48%', width: '9%' }}
      />

      <Door
        src={'/img/room.png'}
        onClick={handleRoom}
        style={{ top: '9%', left: '70%', width: '17.5%' }}
      />

      <IntroductionModal
        open={introductionModalOpen}
        close={() => setIntroductionModalOpen(false)}
      />
      <TicketModal open={ticketModalOpen} close={() => setTicketModalOpen(false)} />

      <FooterWrapper>
        <Footer color={COLORS.LightSilver} />
      </FooterWrapper>
    </Wrapper>
  );
};

export default Home;
