import { configureStore } from '@reduxjs/toolkit';

import { boardApi } from '~/api/BoardAPI';
import { letterApi } from '~/api/LetterAPI';
import { userApi } from '~/api/UserAPI';

import authReducer from './auth';
import letterReducer from './letter';
import modalReducer from './modal';
import userReducer from './user';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    user: userReducer,
    letter: letterReducer,
    [userApi.reducerPath]: userApi.reducer,
    [boardApi.reducerPath]: boardApi.reducer,
    [letterApi.reducerPath]: letterApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(userApi.middleware, letterApi.middleware, boardApi.middleware),
  devTools: process.env.NODE_ENV === 'development',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
