import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import COLORS from '~/constants/Colors';

import { media } from './Styled/Media';

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  /* Pretendard-Thin */
  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff")
      format("woff");
    font-weight: 100;
    font-style: normal;
  }
  /* Pretendard-ExtraLight */
  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff")
      format("woff");
    font-weight: 200;
    font-style: normal;
  }
  /* Pretendard-Light */
  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff")
      format("woff");
    font-weight: 300;
    font-style: normal;
  }
  /* Pretendard-Regular */
  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
      format("woff");
    font-weight: 400;
    font-style: normal;
  }
  /* Pretendard-Medium */
  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff")
      format("woff");
    font-weight: 500;
    font-style: normal;
  }
  /* Pretendard-SemiBold */
  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
      format("woff");
    font-weight: 600;
    font-style: normal;
  }
  /* Pretendard-Bold */
  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff")
      format("woff");
    font-weight: 700;
    font-style: normal;
  }
  /* Pretendard-ExtraBold */
  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff")
      format("woff");
    font-weight: 800;
    font-style: normal;
  }
  /* Pretendard-Black */
  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff")
      format("woff");
    font-weight: 900;
    font-style: normal;
  }

  * {
    font-family: Pretendard;

  }

  p {
    margin-bottom: 0;
  }

  textarea {
    resize: none;
  }

  *:focus{
    outline: none;
  }

  .ant-divider {
    border-top-color: ${COLORS.Manatee};
  }

  .custom.react-calendar {
    pointer-events: none;
    border: 0;
    width: 440px;

    ${media.tablet`
      width: 340px;
    `}

    ${media.mobile`
      width: 340px;
    `}

    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__label__labelText {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
    }

    .react-calendar__month-view__weekdays {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      & abbr {
        text-decoration: none;
      }
    }


    .react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth,
    .react-calendar__month-view__days__day--neighboringMonth {
      color: ${COLORS.BrightGray};

      .inner {
        color: ${COLORS.White};
      }
    }

    .react-calendar__tile {
      padding: 0;
      
      & abbr {
        display: none;
      }
    }

    .react-calendar__month-view__days__day--weekend {
      color: ${COLORS.Black}
    }

    .react-calendar__tile--active {
      color: ${COLORS.Black};
      background-color: ${COLORS.White} !important;
      & abbr {
        color: ${COLORS.White};
        background-color: ${COLORS.Saffron};
        border-radius: 16px;
      }
    }

  }
`;
