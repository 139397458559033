import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ModalState {
  signIn: boolean;
  signUp: boolean;
  findPassword: boolean;
  findEmail: boolean;
  myProfile: boolean;

  noticeBoard: boolean;
  weeklyTopicBoard: boolean;

  letterList: boolean;

  writeLetter: boolean;
  writeLetterReply: boolean;

  guide: boolean;
  menuButton: boolean;
}

const initialState: ModalState = {
  signIn: false,
  signUp: false,
  findPassword: false,
  findEmail: false,
  myProfile: false,

  noticeBoard: false,
  weeklyTopicBoard: false,

  letterList: false,

  writeLetter: false,
  writeLetterReply: false,

  guide: false,
  menuButton: false,
};

export const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    shutter: (
      state,
      { payload }: PayloadAction<{ [key in keyof Partial<ModalState>]: boolean }>,
    ) => {
      Object.entries(payload).forEach(([key, bool]) => {
        state[key] = bool;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const shutter = modal.actions.shutter;

export default modal.reducer;
