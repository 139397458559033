import { Select as antdSelect, Input as antdInput } from 'antd';
import _uniqueId from 'lodash/uniqueId';
import { ChangeEventHandler, FocusEventHandler, HTMLInputTypeAttribute, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

import COLORS from '~/constants/Colors';

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & + div {
    margin-top: 12px;
  }
`;

const StyledInput = styled.input`
  border: 1px solid ${COLORS.PoliceBlue};
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 16px;

  ::placeholder {
    font-size: 14px;
    color: ${COLORS.Manatee};
  }
`;

interface InputProps {
  label?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  name: string;
  disabled?: boolean;
  inputType?: HTMLInputTypeAttribute;
  placeholder?: string;
  style?: CSSObject;
}

export const Input = ({
  label,
  value,
  onChange,
  onBlur,
  name,
  disabled,
  inputType,
  placeholder,
  style,
}: InputProps) => {
  const [id] = useState(_uniqueId('prefix-'));

  return (
    <InputWrapper>
      <Label htmlFor={id}>{label}</Label>

      <StyledInput
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        placeholder={placeholder}
        type={inputType}
        disabled={disabled}
        style={style}
      />
    </InputWrapper>
  );
};

export const Select = styled(antdSelect)`
  & .ant-select-selector {
    border: 1px solid ${COLORS.PoliceBlue} !important;
    border-radius: 5px !important;
    box-shadow: none !important;
  }

  & .ant-select-selection-placeholder {
    color: ${COLORS.Manatee};
  }

  margin-right: 6px;
`;

const CheckboxWrapper = styled.div<{ width?: number }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 24px;
  ${(props) => props.width && `width: ${props.width}px;`}

  & input + label {
    cursor: pointer;
    margin-left: 20px;
    font-size: 16px;
    line-height: 19px;
  }
`;

interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  width?: number;
  style?: CSSObject;
}
export const Checkbox = ({ checked, onChange, label, width, style }: CheckboxProps) => {
  return (
    <CheckboxWrapper width={width} onClick={() => onChange(!checked)} style={style}>
      <input checked={checked} type={'checkbox'} onChange={() => {}} />
      {label && <label>{label}</label>}
    </CheckboxWrapper>
  );
};

export const Label = styled.label`
  min-width: 80px;
`;

export const HelperLabel = styled.label<{ width?: number }>`
  color: ${COLORS.Saffron};
  font-size: 14px;
  line-height: 17px;
  white-space: pre-wrap;
  ${(props) => props.width && `width: ${props.width}px;`}
`;

export const FormWrapper = styled.div`
  position: relative;
  background: ${COLORS.White};
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 100%;
  padding: 16px 24px;
`;

export const CursorContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

interface TextAreaProps {
  value?: string;
  name: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  style?: CSSObject;
}

export const TextArea = ({ value, name, onChange, placeholder, style }: TextAreaProps) => {
  return (
    <antdInput.TextArea
      value={value}
      showCount={{
        formatter: ({ value, count }) => {
          const lines = (value.match(/\n/g) || []).length;
          return `줄바꿈 ${lines}/32 글자수 ${count}/1200`;
        },
      }}
      minLength={300}
      maxLength={1200}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      style={style}
    />
  );
};
