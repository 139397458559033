import { createApi } from '@reduxjs/toolkit/query/react';

import { Board } from '~/classes/Board';
import { SearchBoardDto } from '~/classes/Dto/SearchBoardDto';
import Pagination from '~/classes/Pagination';
import axios, { axiosBaseQuery } from '~/helper/axios';

export const boardApi = createApi({
  reducerPath: 'boardApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getBoard: builder.query<Pagination<Board>, SearchBoardDto>({
      query: (params) => ({ url: '/board', method: 'get', params }),
      transformResponse: (data: Pagination<Board>) => new Pagination(data, Board),
    }),
  }),
});

export const { useGetBoardQuery } = boardApi;

export default {};
