import { createApi } from '@reduxjs/toolkit/query/react';

import { CreateLetterDto } from '~/classes/Dto/CreateLetterDto';
import { CreateLetterReplyDto } from '~/classes/Dto/CreateLetterReplyDto';
import Letter from '~/classes/Letter';
import LetterBox from '~/classes/LetterBox';
import LetterRecipient from '~/classes/LetterRecipient';
import Pagination from '~/classes/Pagination';
import PaginationRequest from '~/classes/PaginationRequest';
import axios, { axiosBaseQuery } from '~/helper/axios';

const getWeeks = async (): Promise<LetterBox> => {
  const { data } = await axios.get<LetterBox>('/letter-box/weeks');

  return new LetterBox(data);
};

export const letterApi = createApi({
  reducerPath: 'letterApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    postLetter: builder.query<Letter, CreateLetterDto>({
      query: (data) => ({ url: '/letter', method: 'post', data }),
    }),
    patchLetter: builder.query<Letter, { letterId: number; originContent: string }>({
      query: (data) => ({ url: '/letter', method: 'patch', data }),
    }),
    postLetterReply: builder.query<Letter, CreateLetterReplyDto>({
      query: (data) => ({ url: '/letter/reply', method: 'post', data }),
    }),
    getSendable: builder.query<{ weekly: boolean; reply: boolean; hasSent: boolean }, void>({
      query: () => ({ url: '/letter/sendable', method: 'get' }),
    }),
    getSent: builder.query<Pagination<Letter>, PaginationRequest>({
      query: (params) => ({ url: '/letter/sent', method: 'get', params }),
      transformResponse: (data: Pagination<Letter>) => new Pagination(data, Letter),
    }),
    getReceived: builder.query<Pagination<LetterRecipient>, PaginationRequest>({
      query: (params) => ({ url: '/letter/received', method: 'get', params }),
      transformResponse: (data: Pagination<LetterRecipient>) =>
        new Pagination(data, LetterRecipient),
    }),
    patchReceiveLike: builder.query<Letter, { letterId: number; isLike: boolean }>({
      query: (data) => ({ url: '/letter/receive-like', method: 'patch', data }),
    }),
    deleteLetter: builder.query<void, { letterId: number }>({
      query: (data) => ({ url: '/letter', method: 'delete', data }),
    }),
    deleteReceivedLetter: builder.query<void, { letterRecipientId: number }>({
      query: (data) => ({ url: '/letter/received', method: 'delete', data }),
    }),
    getLetterList: builder.query<Pagination<Letter>, number>({
      query: (id) => ({ url: `/letter/${id}/list`, method: 'get' }),
      transformResponse: (data: Pagination<Letter>) => new Pagination(data, Letter),
    }),
    patchLetterRead: builder.query<Letter, { letterId: number; isRead: boolean }>({
      query: (data) => ({ url: '/letter/read', method: 'patch', data }),
    }),
  }),
});

export const {
  useLazyPostLetterQuery,
  useLazyPatchLetterQuery,
  useLazyPostLetterReplyQuery,
  useLazyGetSendableQuery,
  useGetSentQuery,
  useGetReceivedQuery,
  useLazyPatchReceiveLikeQuery,
  useLazyDeleteLetterQuery,
  useLazyDeleteReceivedLetterQuery,
  useGetLetterListQuery,
  useLazyPatchLetterReadQuery,
} = letterApi;

export default {
  getWeeks,
};
