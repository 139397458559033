export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum Provider {
  GOOGLE = 'GOOGLE',
  KAKAO = 'KAKAO',
  APPLE = 'APPLE',
}

export enum SubscribeStatus {
  NONE = 'NONE',
  SUBSCRIBED = 'SUBSCRIBED',
  CANCELED = 'CANCELED',
}

export enum ServiceType {
  OFFICIAL = 'OFFICIAL',
}

export enum DeletedReason {
  WITHDRAWAL = 'WITHDRAWAL', // 사용자 탈퇴
  USAGE_RESTRICTIONS = 'USAGE_RESTRICTIONS', // 이용 제한, 제재
}

export default class User {
  id: number;
  email: string;
  realname: string;
  phone: string;
  providers: Provider[];
  birthday: string;
  gender: Gender;
  nickname: string;
  recipient: string;
  address: string;
  addressDetail: string;
  zipCode: string;
  emailVerificationYN: boolean;
  serviceType: ServiceType;
  subscribeStatus: SubscribeStatus;
  deletedReason?: DeletedReason;
  comment?: string;
  isFirstWeek: boolean;
  isPaid: boolean;
  servicePush: boolean;
  letterPush: boolean;
  eventPush: boolean;
  SMSPush: boolean;
  emailPush: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(user: User) {
    this.id = user.id;
    this.email = user.email;
    this.realname = user.realname;
    this.phone = user.phone;
    this.providers = user.providers || [];
    this.birthday = user.birthday;
    this.gender = user.gender;
    this.nickname = user.nickname;
    this.recipient = user.recipient;
    this.address = user.address;
    this.addressDetail = user.addressDetail;
    this.zipCode = user.zipCode;
    this.emailVerificationYN = user.emailVerificationYN;
    this.serviceType = user.serviceType;
    this.subscribeStatus = user.subscribeStatus;
    this.deletedReason = user.deletedReason;
    this.comment = user.comment;
    this.isFirstWeek = user.isFirstWeek;
    this.isPaid = user.isPaid;
    this.servicePush = user.servicePush;
    this.letterPush = user.letterPush;
    this.eventPush = user.eventPush;
    this.SMSPush = user.SMSPush;
    this.emailPush = user.emailPush;
    this.createdAt = new Date(user.createdAt);
    this.updatedAt = new Date(user.updatedAt);
  }
}
