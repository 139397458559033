import styled from 'styled-components';

import { ConfirmButton } from '~/components/Modal/Buttons';
import TitleModal from '~/components/Modal/TitleModal';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { shutter } from '~/reducers/modal';

const Button = styled(ConfirmButton)`
  width: 100%;
`;

const MenuButtonModal = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal.menuButton);

  return (
    <TitleModal
      title={'버튼 모음'}
      open={open}
      close={() => dispatch(shutter({ menuButton: false }))}
      zIndex={5000}
      hideArrowIcon
      width={360}
    >
      <Button
        icon={<></>}
        text={'새 편지 작성'}
        onClick={() => dispatch(shutter({ menuButton: false, writeLetter: true }))}
        style={{ marginBottom: '24px' }}
      />
      <Button
        icon={<></>}
        text={'편지 보관함'}
        onClick={() => dispatch(shutter({ menuButton: false, letterList: true }))}
        style={{ marginBottom: '24px' }}
      />
      <Button
        icon={<></>}
        text={'금주의 주제'}
        onClick={() => dispatch(shutter({ menuButton: false, weeklyTopicBoard: true }))}
        style={{ marginBottom: '24px' }}
      />
      <Button
        icon={<></>}
        text={'UNKNOWN 게시판'}
        onClick={() => dispatch(shutter({ menuButton: false, noticeBoard: true }))}
      />
    </TitleModal>
  );
};
export default MenuButtonModal;
