import { Input } from 'antd';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useLazyPostLetterReplyQuery } from '~/api/LetterAPI';
import { CreateLetterReplyDto } from '~/classes/Dto/CreateLetterReplyDto';
import { LetterStatus } from '~/classes/Letter';
import { DividerBlock, DividerLineBlock } from '~/components/Dividers';
import { CursorContainer, FormWrapper, HelperLabel, TextArea } from '~/components/Form';
import { EmailIcon, LetterIcon } from '~/components/Icons';
import { ConfirmButton } from '~/components/Modal/Buttons';
import TitleModal from '~/components/Modal/TitleModal';
import { CenterContainer, SpaceBetweenContainer } from '~/components/Styled/Containers';
import { P14 } from '~/components/Styled/Texts';
import COLORS from '~/constants/Colors';
import ErrorMessage from '~/constants/ErrorMessage';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { shutter } from '~/reducers/modal';

const P = styled(P14)`
  line-height: 24px;
  margin-left: 10px;
`;

const Week = styled(P14)`
  line-height: 24px;
  color: ${COLORS.Manatee};
`;

const LetterReplyModal = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const open = useSelector((state) => state.modal.writeLetterReply);

  const [postLetterReply] = useLazyPostLetterReplyQuery();

  const user = useSelector((state) => state.auth.me);
  const letter = useSelector((state) => state.letter.lastReadLetter);

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik<CreateLetterReplyDto>({
      initialValues: {
        letterId: letter.id,
        originContent: '',
        letterStatus: LetterStatus.SUBMITTED,
      },
      validationSchema: Yup.object({
        originContent: Yup.string().required().min(300).max(1200),
      }),
      onSubmit: async (values) => {
        console.log(values);

        const lines = (values.originContent.match(/\n/g) || []).length;
        if (lines > 32) {
          return alert.error('최대 줄바꿈 제한을 넘었습니다.');
        }

        const { isSuccess, isError, error } = await postLetterReply(values);

        if (isSuccess) {
          alert.success(
            '작성하신 편지는 우편함에 잘 넣어두었어요.\n편지는 다음주 월요일 오후 6시 웹사이트에서 발송됩니다 :)',
          );

          dispatch(shutter({ writeLetterReply: false }));
        }

        if (isError) {
          if (error.message === ErrorMessage.letter.alreadyReplySent) {
            alert.error('이미 답장을 발송했습니다.');
          } else if (error.message === ErrorMessage.letter.notMyReceived) {
            alert.error('내가 받지 않은 편지에 답장했습니다.');
          } else {
            alert.error('답장 보내기에 실패했습니다.');
          }
        }
      },
    });

  useEffect(() => {
    setFieldValue('letterId', letter.id);
  }, [setFieldValue, letter.id]);

  return (
    <TitleModal
      title="답장하기"
      open={open}
      close={() => dispatch(shutter({ writeLetterReply: false }))}
      zIndex={3000}
    >
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <SpaceBetweenContainer>
            <CursorContainer>
              <EmailIcon fill={COLORS.PoliceBlue} />
              <P>{user.nickname}</P>
            </CursorContainer>
            <Week>{letter.letterBox?.weeks}주차</Week>
          </SpaceBetweenContainer>
          <CursorContainer>
            <P style={{ marginLeft: '34px' }}>받는 사람: </P>
            <P>{letter.senderUser?.nickname}</P>
          </CursorContainer>

          <DividerLineBlock height={40} />

          <TextArea
            name="originContent"
            onChange={handleChange}
            placeholder="편지 내용을 입력하세요 :)"
            style={{ height: 482 }}
          />

          <DividerBlock height={40} />

          <CenterContainer style={{ height: '17px' }}>
            {values.originContent.length < 300 && (
              <HelperLabel style={{ marginBottom: '10px' }}>
                최소 300자 이상 작성해주셔야 합니다.
              </HelperLabel>
            )}
          </CenterContainer>

          <CenterContainer>
            <ConfirmButton
              disabled={values.originContent.length === 0 || !!errors.originContent}
              icon={<LetterIcon />}
              text={'보내기'}
              type="submit"
            />
          </CenterContainer>
        </form>
      </FormWrapper>
    </TitleModal>
  );
};

export default LetterReplyModal;
