import { SVGProps } from 'react';

import COLORS from '~/constants/Colors';

import { ReactComponent as ArrowBack } from './arrow_back.svg';
import { ReactComponent as ArrowForward } from './arrow_forward.svg';
import { ReactComponent as ArrowLeft } from './arrow_left.svg';
import { ReactComponent as ArrowRight } from './arrow_right.svg';
import { ReactComponent as CalendarMonth } from './calendar_month.svg';
import { ReactComponent as ChangeCircle } from './change_circle.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as CheckYellow } from './check_yellow.svg';
import { ReactComponent as CloseYellow } from './close_yellow.svg';
import { ReactComponent as CreditCard } from './credit_card.svg';
import { ReactComponent as DeleteOutline } from './delete_outline.svg';
import { ReactComponent as DoubleArrowLeft } from './double_arrow_left.svg';
import { ReactComponent as DoubleArrowRight } from './double_arrow_right.svg';
import { ReactComponent as Draft } from './draft.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as ErrorYellow } from './error_yellow.svg';
import { ReactComponent as Favorite } from './favorite.svg';
import { ReactComponent as FavoriteBorder } from './favorite_border.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Letter } from './letter.svg';
import { ReactComponent as LocalShipping } from './local_shipping.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Mobile } from './mobile.svg';
import { ReactComponent as MoreVert } from './more_vert.svg';
import { ReactComponent as PushPin } from './push_pin.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as Star } from './star.svg';

interface Props extends SVGProps<SVGSVGElement> {}

export const CheckIcon = (props: Props) => <Check fill="white" {...props} />;
export const ArrowBackIcon = (props: Props) => (
  <ArrowBack fill="white" {...props} cursor="pointer" />
);
export const CloseYellowIcon = (props: Props) => (
  <CloseYellow fill="white" {...props} cursor="pointer" />
);
export const EditIcon = (props: Props) => <Edit fill="black" {...props} />;
export const CalendarMonthIcon = (props: Props) => (
  <CalendarMonth fill="#3F4C5F" cursor="pointer" {...props} />
);
export const ChangeCircleIcon = (props: Props) => <ChangeCircle fill="black" {...props} />;
export const LetterIcon = (props: Props) => <Letter fill="white" {...props} />;
export const EmailIcon = (props: Props) => <Email fill="white" {...props} />;
export const PushPinIcon = (props: Props) => <PushPin fill="black" {...props} />;
export const StarIcon = (props: Props) => <Star fill="black" {...props} />;
export const SendIcon = (props: Props) => <Send fill="black" {...props} />;
export const FavoriteIcon = (props: Props) => <Favorite fill="black" {...props} />;
export const FavoriteBorderIcon = (props: Props) => <FavoriteBorder fill="black" {...props} />;
export const MoreVertIcon = (props: Props) => <MoreVert fill="#3F4C5F" {...props} />;
export const DeleteOutlineIcon = (props: Props) => <DeleteOutline fill="white" {...props} />;
export const InstagramIcon = (props: Props) => <Instagram {...props} />;
export const ErrorYellowIcon = (props: Props) => <ErrorYellow {...props} />;

export const ArrowLeftIcon = (props: Props) => <ArrowLeft {...props} fill="#3F4C5F" />;
export const ArrowRightIcon = (props: Props) => <ArrowRight {...props} fill="#3F4C5F" />;
export const DoubleArrowLeftIcon = (props: Props) => <DoubleArrowLeft {...props} fill="#3F4C5F" />;
export const DoubleArrowRightIcon = (props: Props) => (
  <DoubleArrowRight {...props} fill="#3F4C5F" />
);

export const ArrowForwardIcon = (props: Props) => <ArrowForward {...props} />;
export const CreditCardIcon = (props: Props) => <CreditCard {...props} />;
export const LocalShippingIcon = (props: Props) => <LocalShipping {...props} />;

export const MailIcon = (props: Props) => <Mail {...props} />;
export const MobileIcon = (props: Props) => <Mobile {...props} />;
export const DraftIcon = (props: Props) => <Draft {...props} />;
export const CheckYellowIcon = (props: Props) => <CheckYellow color={COLORS.Saffron} {...props} />;
