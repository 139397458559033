import { css } from 'styled-components';

export const size: { [key: string]: number } = {
  tablet: 820,
  mobile: 680,
};

export const media = {
  tablet: (...args: any) => css`
    @media (min-width: ${size.mobile + 1}px) and (max-width: ${size.tablet}px) {
      ${css.apply(null, args)}
    }
  `,
  mobile: (...args: any) => css`
    @media (max-width: ${size.mobile}px) {
      ${css.apply(null, args)}
    }
  `,
};
