import { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { media } from '../Styled/Media';
import Pagination, { PaginationProps } from './Pagination';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 80px;

  ${media.mobile`
    margin-top: 0px;
  `}
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TableHeader = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
  align-items: center;
`;

const TableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 450px;
`;

const TableRowWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 4px 0;

  &:hover {
    background-color: #e3e5e7;
    border-radius: 10px;
  }
`;

const TableContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
`;

const TableNoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  height: 100%;
`;

export interface Column<T> {
  title: string | ReactNode;
  index: string;
  style?: CSSObject;
  onCellClick?: (cell: T) => void;
  render?: (data: any, root: T) => ReactNode;
}

interface Props<T> {
  dataSource: T[];
  columns: Column<T>[];
  noContentDescription: string;
  onRowClick: (cell: T) => void;
  pagination: PaginationProps;
}

const Table = <T extends any>({
  dataSource,
  columns,
  noContentDescription,
  onRowClick,
  pagination,
}: Props<T>) => {
  return (
    <Wrapper>
      <TableHeaderWrapper>
        {columns.map((column) => (
          <TableHeader key={column.title + column.index} style={column.style}>
            {column.title}
          </TableHeader>
        ))}
      </TableHeaderWrapper>

      <TableContentWrapper>
        {dataSource.map((data) => (
          <TableRowWrapper key={data['id']} onClick={() => onRowClick(data)}>
            {columns.map((column, index) => (
              <TableContent
                key={data['id'] + column.index + index}
                style={column.style}
                onClick={() => column.onCellClick && column.onCellClick(data)}
              >
                {column.render ? column.render(data[column.index], data) : data[column.index]}
              </TableContent>
            ))}
          </TableRowWrapper>
        ))}
        {dataSource.length === 0 && <TableNoContent>{noContentDescription}</TableNoContent>}
      </TableContentWrapper>

      <Pagination {...pagination} />
    </Wrapper>
  );
};

export default Table;
