import styled from 'styled-components';

import COLORS from '~/constants/Colors';

export const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShadowContainer = styled.div`
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 4px ${COLORS.PoliceBlue10};
`;
