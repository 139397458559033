import { useState } from 'react';
import styled from 'styled-components';

import TabModal from '~/components/Modal/TabModal';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { shutter } from '~/reducers/modal';

import ReceivedLetter from './ReceivedLetter';
import SentLetter from './SentLetter';

const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
`;

const LetterListModal = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal.letterList);
  const [activeTab, setActiveTab] = useState<'left' | 'right'>('left');

  return (
    <TabModal
      title="편지보관함"
      activeTab={activeTab}
      leftTitle={'받은 편지함'}
      rightTitle={'보낸 편지함'}
      leftClick={() => setActiveTab('left')}
      rightClick={() => setActiveTab('right')}
      open={open}
      close={() => dispatch(shutter({ letterList: false }))}
    >
      <Wrapper>
        {activeTab === 'left' && <ReceivedLetter />}
        {activeTab === 'right' && <SentLetter />}
      </Wrapper>
    </TabModal>
  );
};

export default LetterListModal;
