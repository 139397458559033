import styled from 'styled-components';

import { DividerBlock, DividerLineBlock } from '~/components/Dividers';
import { FormWrapper } from '~/components/Form';
import { FavoriteBorderIcon, InstagramIcon } from '~/components/Icons';
import { ConfirmButton } from '~/components/Modal/Buttons';
import { LetterContainer } from '~/components/Modal/Containers';
import TitleModal from '~/components/Modal/TitleModal';
import {
  CenterContainer,
  FlexContainer,
  SpaceBetweenContainer,
} from '~/components/Styled/Containers';
import { P14 } from '~/components/Styled/Texts';
import COLORS from '~/constants/Colors';
import { contentParser } from '~/helper/util';

const P = styled(P14)`
  line-height: 24px;
  margin-left: 10px;
`;

const Week = styled(P14)`
  display: flex;
  line-height: 24px;
  color: ${COLORS.Manatee};
  justify-content: center;
`;

interface Props {
  open: boolean;
  close: () => void;
}

const IntroductionModal = ({ open, close }: Props) => {
  return (
    <TitleModal title="UNKNOWN 소개" open={open} close={close}>
      <FormWrapper style={{ margin: '20px 0' }}>
        <SpaceBetweenContainer>
          <FlexContainer>
            <FavoriteBorderIcon />
            <P>UNKNOWN</P>
          </FlexContainer>
          <Week>{'2023. 06. 05.'}</Week>
        </SpaceBetweenContainer>

        <SpaceBetweenContainer style={{ marginLeft: '24px' }}>
          <P>받는 사람: UNKNOWNs</P>
          <Week>1주차</Week>
        </SpaceBetweenContainer>

        <DividerLineBlock height={40} />

        <LetterContainer height={473}>
          {contentParser(
            `“요즘, 즐거우신가요?”
            혹시 대답이 조금이라도 망설여지셨다면, 잘 오셨습니다.

            반갑습니다.
            이곳은 당신의 일상 속 또 하나의 즐거움이 될 공간,
            레터 언노운입니다.

            단조로운 일상 속 지쳐가는 우리에게
            일주일이 기다려질 설레는 일 하나씩만 생길 수 있다면 얼마나 좋을까요?
            새로 산 옷 택배도, 새로 나온 넷플릭스 시리즈도,
            더 이상 기다려지지 않는 여러분에게
            이름도, 나이도, 성별도 모르는 누군가의 편지 한 통이 매주 당신을 찾아갑니다.

            언노운에서 여러분은 이름조차 모르는 타인과
            안전하고 편안하게 편지를 주고 받을 수 있습니다.

            언노운에선 당신이 편지를 보내지 않았더라도,
            매주 당신의 집에 새로운 익명 편지가 도착하게 됩니다.
            더 이상 우체통은 고지서 보관함이 아닌, 매주 설레며 열어보는 선물 상자가 될 겁니다.

            바쁘고 지치는 일상 속에서 따뜻하고 싶은 날, 연결되고 싶은 날,
            언노운 앱을 이용해보세요.
            익명의 누군가에게 마음과 생각을 담은 편지를 보낼 수 있습니다.
            물론, 받은 익명의 편지에 답장도 할 수 있습니다.

            레터 언노운에서는 받는 사람도, 보내는 사람도 절대 서로가 누군지 알 수 없습니다. 오히려 완전한 타인이기에, 진실되고 편안한 이야기가 가능해집니다.

            마지막으로 편지를 받아본 게 언제인가요?
            마지막으로 편지를 써 본 건 언제인지요.
            누가 보냈는지 모르지만 마음이 고스란히 전해지고, 누구가 받을지 모르지만 설레이는, 따뜻한 익명 편지를 시작해보세요.
            장담컨대, 다음 주가 기다려지기 시작할 겁니다.`,
          )}
        </LetterContainer>
      </FormWrapper>

      <DividerBlock height={20} />

      <CenterContainer>
        <ConfirmButton
          icon={<InstagramIcon />}
          text={'인스타그램'}
          onClick={() => {
            window.open(
              'https://www.instagram.com/letterunknown_official/',
              '_blank',
              'noopener,noreferrer',
            );
          }}
        />
      </CenterContainer>

      <DividerBlock height={20} />
    </TitleModal>
  );
};
export default IntroductionModal;
