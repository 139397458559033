import styled from 'styled-components';

import COLORS from '~/constants/Colors';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { signOut } from '~/reducers/auth';
import { shutter } from '~/reducers/modal';

import { media } from '../Styled/Media';

const Wrapper = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: center;
  padding: 0;
  height: 72px;
  width: 100%;

  ${media.mobile`
    height: 60px;
    padding-right: 0;
  `}
`;

const Button = styled.button`
  cursor: pointer;
  padding: 16px;
  color: ${COLORS.White};
  border: 0;
  background-color: unset;
`;

const Header = () => {
  const dispatch = useDispatch();
  const isSignIn = useSelector((state) => state.auth.isSignIn);

  const handleSignIn = () => {
    dispatch(shutter({ signIn: true }));
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <Wrapper>
      {isSignIn ? (
        <>
          <Button onClick={handleSignOut}>로그아웃</Button>
        </>
      ) : (
        <>{/* <Button onClick={handleSignIn}>회원가입/로그인</Button> */}</>
      )}
    </Wrapper>
  );
};

export default Header;
