import moment from 'moment';
import { Fragment } from 'react';

import { AmountOfRecipient } from '~/classes/Letter';

export const getAmountOfRecipientLabel = (amountOfRecipient: AmountOfRecipient) => {
  switch (amountOfRecipient) {
    case AmountOfRecipient.LESS:
      return '소수';
    case AmountOfRecipient.MORE:
      return '다수';
  }
};

export const dateFormatting = (date: Date, format: string = 'YY.MM.DD') => {
  return moment(date).format(format);
};

export const contentParser = (content: string) => {
  return content
    .replace(/\\n/g, '\n') // OS에 따라 잘 수정해야함
    .split('\n')
    .map((text, i) =>
      i ? (
        [<br key={`${i}-br`} />, <Fragment key={i}>{text}</Fragment>]
      ) : (
        <Fragment key={i}>{text}</Fragment>
      ),
    );
};

export const phoneToDisplay = (phone: string) => {
  return phone.slice(0, 3) + '-' + phone.slice(3, 7) + '-' + phone.slice(7);
};

export const getWeeksDetail = (weeks: number) => {
  const startDate = moment(new Date('2022-12-05'), '2022-12-05');
  const endDate = moment(startDate);

  startDate.add((weeks - 1) * 7, 'day');
  endDate.add(weeks * 7, 'day');

  return `${startDate.format('YY.MM.DD')}-${endDate.format('YY.MM.DD')}`;
};

export const getDiffHours = (s: Date, e: Date) => {
  return moment(s).diff(moment(e), 'hours');
};

export const toCustomLocale = (date: Date) => {
  return date.toLocaleString().slice(0, -3);
};
