import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useLazyPostLetterQuery } from '~/api/LetterAPI';
import { CreateLetterDto } from '~/classes/Dto/CreateLetterDto';
import { AmountOfRecipient, LetterStatus } from '~/classes/Letter';
import { DividerBlock, DividerLineBlock } from '~/components/Dividers';
import { Checkbox, CursorContainer, FormWrapper, HelperLabel, TextArea } from '~/components/Form';
import { ChangeCircleIcon, LetterIcon } from '~/components/Icons';
import { ConfirmButton } from '~/components/Modal/Buttons';
import TitleModal from '~/components/Modal/TitleModal';
import { CenterContainer, SpaceBetweenContainer } from '~/components/Styled/Containers';
import { P14 } from '~/components/Styled/Texts';
import COLORS from '~/constants/Colors';
import { getAmountOfRecipientLabel } from '~/helper/util';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { shutter } from '~/reducers/modal';

const P = styled(P14)`
  line-height: 24px;
  margin-left: 10px;
`;

const Week = styled(P14)`
  line-height: 24px;
  color: ${COLORS.Manatee};
`;

const LetterWriteModal = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const open = useSelector((state) => state.modal.writeLetter);

  const [postLetter] = useLazyPostLetterQuery();

  const weeks = useSelector((state) => state.letter.letterBox.weeks);

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik<CreateLetterDto>({
    initialValues: {
      amountOfRecipient: AmountOfRecipient.MORE,
      agreeOfBestLetter: false,
      originContent: '',
      letterStatus: LetterStatus.SUBMITTED,
    },
    validationSchema: Yup.object({
      originContent: Yup.string().required().min(300).max(1200),
    }),
    onSubmit: async (values) => {
      console.log(values);

      const lines = (values.originContent.match(/\n/g) || []).length;
      if (lines > 32) {
        return alert.error('최대 줄바꿈 제한을 넘었습니다.');
      }

      const { isSuccess, isError } = await postLetter(values);

      if (isSuccess) {
        alert.success(
          '작성하신 편지는 우편함에 잘 넣어두었어요.\n편지는 다음주 월요일 오후 6시 웹사이트에서 발송됩니다 :)',
        );
        dispatch(shutter({ writeLetter: false }));
      }

      if (isError) {
        alert.error('금주엔 이미 편지를 작성하셨습니다.');
      }
    },
  });

  return (
    <TitleModal
      title="새편지 작성"
      open={open}
      close={() => dispatch(shutter({ writeLetter: false }))}
    >
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <SpaceBetweenContainer>
            <CursorContainer
              onClick={() =>
                setFieldValue(
                  'amountOfRecipient',
                  values.amountOfRecipient === AmountOfRecipient.MORE
                    ? AmountOfRecipient.LESS
                    : AmountOfRecipient.MORE,
                )
              }
            >
              <ChangeCircleIcon />
              <P>{getAmountOfRecipientLabel(values.amountOfRecipient)}의 unknown에게</P>
            </CursorContainer>
            <Week>{weeks}주차</Week>
          </SpaceBetweenContainer>
          <CursorContainer
            onClick={() => setFieldValue('agreeOfBestLetter', !values.agreeOfBestLetter)}
          >
            <Checkbox
              checked={values.agreeOfBestLetter}
              onChange={() => {}}
              width={24}
              style={{ justifyContent: 'center' }}
            />
            <P>모두의 편지 동의</P>
          </CursorContainer>

          <DividerLineBlock height={40} />

          <TextArea
            name="originContent"
            onChange={handleChange}
            placeholder="편지 내용을 입력하세요 :)"
            style={{ height: 482 }}
          />

          <DividerBlock height={40} />

          <CenterContainer style={{ height: '17px' }}>
            {values.originContent.length < 300 && (
              <HelperLabel style={{ marginBottom: '10px' }}>
                최소 300자 이상 작성해주셔야 합니다.
              </HelperLabel>
            )}
          </CenterContainer>

          <CenterContainer>
            <ConfirmButton
              disabled={values.originContent.length === 0 || !!errors.originContent}
              icon={<LetterIcon />}
              text={'보내기'}
              type="submit"
            />
          </CenterContainer>
        </form>
      </FormWrapper>
    </TitleModal>
  );
};

export default LetterWriteModal;
